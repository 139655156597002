import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { useHistory } from 'react-router';
import Line from '../../assets/images/line.svg';
import logo from '../../assets/images/logo.png';
import './index.css';
import api from '../../api';
import { adminErrorHandler } from '../../api/admin';

function AdminLoginPage({ history }) {
  useEffect(() => { }, []);

  const onLogin = async (data) => {
    await api.admin
      .login(data)
      .then((resp) => {
        if (resp.data) {
          localStorage.setItem('adminToken', resp.data.token);
          localStorage.setItem('role', resp.data.role);
          localStorage.setItem('admin', resp.data.username);
          history.push('/dashboard');
          window.location.reload();
        }
      })
      .catch((err) => {
        adminErrorHandler(err);
        if (err.response.status === 400) {
          message.error('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง');
        } else {
          message.error('เกิดข้อผิดพลาด');
        }
      });
  };

  return (
    <body>
      <div className='center' style={{ marginTop: '15%' }}>
        <img src={logo} style={{ width: '350px' }}></img>
      </div>
      <div className='center'>
        <h2>เข้าสู่ระบบ Admin</h2>
      </div>
      <div className='center'>
        <Form layout='vertical' onFinish={onLogin}>
          <Form.Item
            name='username'
            label='ชื่อผู้ใช้งาน'
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              style={{ width: '100%' }}
              placeholder='ชื่อผู้ใช้งาน'
            ></Input>
          </Form.Item>
          <Form.Item
            name='password'
            label='รหัสผ่าน'
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input
              type='password'
              style={{ width: '100%' }}
              placeholder='รหัสผ่าน'
            ></Input>
          </Form.Item>
          <Form.Item style={{ width: '100%' }}>
            <Button
              htmlType='submit'
              className='loginButton'
              style={{
                width: '100%',
                color: '#FDCE2D',
                background: 'linear-gradient(to bottom, #535353, #222222)',
              }}
            >
              เข้าสู่ระบบ
            </Button>
          </Form.Item>
        </Form>
      </div>
    </body>
  );
}

export default AdminLoginPage;
