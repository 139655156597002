import React, { useState, useEffect } from 'react';
import { Button, Card, Checkbox, Form, Input, message } from 'antd';
import { useHistory } from 'react-router';
import Line from '../../assets/images/line.svg';
import logo from '../../assets/images/logo.png';
import './index.css';
import api from '../../api';

function AgreementPage({ history }) {
  useEffect(() => { }, []);

  const onAgree = async () => {
    history.push('/handicap');
    window.location.reload();
  };

  const onLogout = async () => {
    localStorage.clear();
    history.push('/');
  };

  return (
    <div className='container-agreement'>
      <body>
        <div
          style={{
            width: '100%',
            background: 'black',
            textAlign: 'center',
            position: 'fixed',
            top: 0,
            height: '100px',
            zIndex: 1000,
          }}
        >
          <img src={logo} style={{ margin: 'auto', width: '350px' }}></img>
        </div>

        <Card
          style={{
            overflow: 'auto',
            margin: 'auto',
            marginTop: '100px',
            marginBottom: '20px',
          }}
        >
          <table
            bgcolor='#FFFFFF'
            border='0'
            cellspacing='10'
            cellpadding='10'
          >
            <tbody>
              <tr>
                <td>
                  <a name='top' id='top'></a>
                  <span style={{ color: 'red' }}>
                    ประกาศสำคัญ ถ้าหากทางบริษัทตรวจสอบพบ user
                    ของท่านมีการเล่นที่ผิดปกติหรือทุจริตในการเล่น (Abnormal)
                    ทางบริษัทจะสามารถ ทำการ VOID หรือ CANCEL บิลนั้นทิ้งได้ทันที
                    โดยไม่ต้องแจ้งล่วงหน้าไม่ว่าจะบิลนั้นจะกำลังแข่งขันอยู่หรือแข่งขันจบไปแล้ว
                    ซึ่งการตัดสินใจของทางบริษัทถือเป็นที่สิ้นสุด
                  </span>
                  <div id='path'>
                    <span classNameName='text_11_GR'> &gt; </span>{' '}
                    เงี่อนไขและกติกา
                    <br />
                  </div>
                  <div class='information_text termsList'>
                    <p>
                      <strong>
                        ข้อมูลสำคัญ:
                        โปรดอ่านข้อมูลที่กำหนดไว้ด้านล่างอย่างละเอียดก่อนที่จะยอมรับข้อกำหนดและเงื่อนไขนี้จากนั้นให้พิมพ์และเก็บข้อกำหนดและเงื่อนไขนี้ไว้พร้อมกันกับข้อมูลการทำรายการ
                        กฎกติการะเบียบ
                        ข้อบังคับและวิธีการชำระเงินที่เกี่ยวข้องกับการใช้เว็บไซต์นี้
                      </strong>
                    </p>
                    <h1>บทนำ</h1>
                    <p>
                      www.UFAbet.com,(“บริษัท”)
                      ประกอบธุรกิจเว็บไซต์การเดิมพันด้านกีฬาออนไลน์และเกม
                      การแข่งขัน
                      ที่กำกับดูแลและได้รับอนุญาตตามกฎหมายและระเบียบข้อบังคับของประเทศฟิลิปปินส์
                      ในการให้บริการการพนันระยะไกล "บริษัท"
                      เป็นผู้ดำเนินการเว็บไซต์ ‘www.UFAbet.com’ ซึ่งบริษัท
                      ให้บริการที่ได้รับอนุญาตผ่านทางเว็บไซต์นี้ <br />
                      <br />
                      การรับแจ้งและการยอมรับข้อกำหนดและเงื่อนไขนี้ถือเป็นข้อตกลง
                      ซึ่งคุณได้ยอมรับโดยการเลือก "ตกลง" ในหน้านี้
                      และทำการเข้าสู่เว็บไซต์ต่อไป เมื่อคุณกระทำการดังกล่าว
                      จะถือเป็นการทำข้อตกลง
                      ที่มีผลผูกพันทางกฎหมายตามข้อกำหนดและเงื่อนไขนี้ระหว่าง (ก)
                      คุณ และผู้ใช้ปลายทางของเว็บไซต์ (“ลูกค้า”) และ (ข)
                      "บริษัท"
                    </p>
                    <h1>ข้อกำหนดและเงื่อนไขทั่วไป</h1>
                    <p>
                      ต่อไปนี้เป็นข้อกำหนดและเงื่อนไขที่ควบคุมการใช้เว็บไซต์นี้
                      กิจกรรมของลูกค้าทั้งหมดบนเว็บไซต์นี้
                      ต้องอยู่ภายใต้และควบคุมโดยข้อกำหนดและเงื่อนไขนี้{' '}
                    </p>
                    <h1>1. คำจำกัดความ</h1>
                    !-- 1.1 --
                    <h2>
                      <strong>1.1.</strong>
                      <span>คำต่อไปนี้ให้มีความหมายดังต่อไปนี้</span>
                    </h2>
                    <h3>
                      <strong>1.1.1.</strong>
                      <span>
                        ข้อมูลการเดิมพัน: ชื่อเหตุการณ์ สถานที่ สถิติ คะแนน
                        วันที่ และ/หรือเวลา ข้อมูล เกี่ยวกับเหตุการณ์
                        ทีมหรือชื่อผู้เล่น สถิติ และข้อมูลที่เกี่ยวกับสถิติ
                        แต้มต่อ ราคา แฮนดิแคป ประเภทการเดิมพัน เงินเดิมพัน
                        ข้อมูลเกี่ยวกับการเดิมพันอย่างใดอย่างหนึ่ง ประเภทการ
                        เดิมพัน หรือเหตุการณ์เกี่ยวกับเงินเดิมพันหรือการเดิมพัน
                      </span>
                    </h3>
                    <h3>
                      <strong>1.1.2.</strong>
                      <span>
                        บริการของบริษัท: บริการเดิมพัน การวางเงินเดิมพัน
                        และบริการเกมการแข่งขันผ่านอุปกรณ์
                        การพนันระยะไกลที่จัดไว้ให้ทางเว็บไซต์และโดยบริษัท
                      </span>
                    </h3>
                    <h3>
                      <strong>1.1.3.</strong>
                      <span>
                        เอกสาร: ข้อกำหนดและเงื่อนไขนี้ นโยบายความเป็นส่วนตัว
                        กฎกติกาและระเบียบข้อบังคับ ในการเดิมพัน
                      </span>
                    </h3>
                    <h3>
                      <strong>1.1.4.</strong>
                      <span>
                        เหตุการณ์: เหตุการณ์ อุบัติการณ์ การเกิดขึ้น หรือ
                        สิ่งที่เกิดขึ้นที่บริษัทอนุญาตให้ทำการเดิม พัน
                        โดยผ่านทางเว็บไซต์และบริการของบริษัท{' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>1.1.5.</strong>
                      <span>
                        ผิดกฎหมาย: ให้รวมถึง
                        แต่ไม่จำกัดเพียงความพยายามที่จะเปลี่ยนแปลงเอกสาร
                        ขีดจำกัดการเดิมพัน ขีดจำกัดการชนะ การโจรกรรมข้อมูล
                        การใช้โดยไม่ได้รับอนุญาต หรือการใช้บัญชีลูกค้า
                        หรือรหัสเข้าสู่ระบบในทางที่ผิด ความพยายามที่จะแทรกแซง
                        หรือ เลี่ยงกลไกความปลอดภัยที่มีอยู่บนเว็บไซต์
                        หรือระบบหรือเครือข่ายของบริษัทอย่างผิด กฎหมาย
                        ความพยายามที่จะโอนเงินจากบัญชีลูกค้าที่เป็นบุคคลภายนอก
                        การกระทำใดๆ
                        หรือการละเว้นผ่านการใช้เว็บไซต์ของบริการของบริษัท
                        ก่อให้เกิดความเสียหายต่อบริษัทหรือ ต่อบุคคลภายนอก
                        การจัดหารหัสลูกค้าปลอม การสมคบคิด การรวมหัวกันกระทำการ
                        หรือ หลอกลวง บริษัท และ/หรือ "การทิ้งชิพ" การกระทำใดๆ
                        และ/หรือการละเว้น
                        ไม่ปฏิบัติตามข้อกำหนดเงื่อนไขที่บังคับใช้เหล่านี้ และ
                        การกระทำใดๆ และ/หรือการละเว้น
                        ซึ่งบริษัทเห็นควรว่าเป็นการฉ้อฉล และ/หรือเป็นการกระทำผิด
                      </span>
                    </h3>
                    <h3>
                      <strong>1.1.6.</strong>
                      <span>
                        ตลาด: ประเภทการเดิมพันที่เกี่ยวกับ หรือความเป็นไปได้
                        และ/หรือตัวเลือกสำหรับวางเดิมพัน
                        เหตุการณ์ใดเหตุการณ์หนึ่ง ตามที่เสนอโดยบริษัท
                        ผ่านทางเว็บไซต์
                      </span>
                    </h3>
                    <h3>
                      <strong>1.1.7.</strong>
                      <span>
                        ข้อมูลส่วนบุคคล:
                        ให้มีความหมายเช่นเดียวกันกับในนโยบายความเป็นส่วนตัวที่นอกเหนือ
                        ไปจากข้อมูลประจำตัวลูกค้า (ดังระบุไว้ในที่นี้)
                      </span>
                    </h3>
                    <h3>
                      <strong>1.1.8.</strong>
                      <span>
                        นโยบายความเป็นส่วนตัว:
                        นโยบายของบริษัทเกี่ยวกับความเป็นส่วนตัวและข้อมูลที่ใช้
                        เกี่ยวกับลูกค้า เว็บไซต์ และบริการของบริษัท
                        ที่มีอยู่บนเว็บไซต์นี้เป็นครั้งคราว
                      </span>
                    </h3>
                    <h3>
                      <strong>1.1.9.</strong>
                      <span>
                        เขตอำนาจที่ห้าม: อเมริกา , ฮ่องกง, ไต้หวัน, เวียดนาม ,
                        ฟิลิปปินส์, สิงคโปร์, สหราชอาณาจักร, ออสเตรเลีย
                      </span>
                    </h3>
                    <h3>
                      <strong>1.1.10.</strong>
                      <span>
                        กฎกติกาและระเบียบข้อบังคับการเดิมพัน:
                        กฎกติกาสำหรับการเดิมพันบนเว็บไซต์ และการใช้
                        บริการของบริษัท ที่เผยแพร่โดยบริษัท
                        เป็นครั้งคราวบนเว็บไซต์
                      </span>
                    </h3>
                    <h3>
                      <strong>1.1.11.</strong>
                      <span>
                        ข้อกำหนดและเงื่อนไข: เอกสาร บทบัญญัิติในเอกสาร
                        และเอกสารทั้งหมดที่อ้างถึงในที่นี้
                        สามารถอ่านได้จากเว็บไซต์
                      </span>
                    </h3>
                    <h3>
                      <strong>1.1.12.</strong>
                      <span>
                        เว็บไซต์: ที่อยู่เว็บไซต์นี้คือ www.UFAbet.com
                      </span>
                    </h3>
                    !-- 1.2 --
                    <h1>2. การตีความ</h1>
                    <h2>
                      <strong>2.1.</strong>
                      <span>
                        เว้นแต่ระบุเป็นลายลักษณ์อักษรโดยเจาะจง การอ้างอิงใดๆ
                        ในที่นี้เกี่ยวกับ
                      </span>
                    </h2>
                    <h3>
                      <strong>2.1.1. </strong>
                      <span>
                        บทกฎหมาย การตรากฎหมาย หรือบทบัญญัติแห่งกฎหมาย
                        รวมการอ้างอิงถึง บทกฎหมาย การตรากฎหมาย
                        บทบัญญัติแห่งกฎหมาย ที่แก้ไข หรือตราขึ้นใหม่
                        หรือทั้งสองอย่าง เป็นครั้งคราว
                        และอ้างอิงถึงกฎหมายรองที่ตราขึ้นประกอบกัน
                      </span>
                    </h3>
                    <h3>
                      <strong>2.1.2 </strong>
                      <span>
                        เอกสารเป็นการอ้างอิงถึงเอกสารที่จัดทำเสริมขึ้นหรือมีการปรับเปลี่ยนเนื้อหาเป็นครั้งคราว
                      </span>
                    </h3>
                    <h3>
                      <strong>2.1.3.</strong>
                      <span>
                        เอกพจน์ รวมถึง พหูพจน์ และในทางกลับกัน ส่วนเพศชาย รวมถึง
                        เพศหญิง และเพศกลาง และในทางกลับกัน
                      </span>
                    </h3>
                    <h3>
                      <strong>2.1.4. </strong>
                      <span>
                        บุคคล รวมถึง บุคคลธรรมดา บริษัท ห้างหุ้นส่วน ห้างร้าน
                        บริษัทคอร์ปอร์เรชั่น สมาคม องค์กร รัฐบาล รัฐ
                        องค์ภาครัฐหรือรัฐวิสาหกิจ มูลนิธิ และทรัสต์
                        (ในแต่ละกรณีไม่ว่าจะ มีสภาพนิติบุคคลแยกกันหรือไม่ก็ตาม)
                      </span>
                    </h3>
                    <h3>
                      <strong>2.1.5. </strong>
                      <span>
                        ปี เดือน หรือสัปดาห์ คือ ปี เดือน
                        และสัปดาห์ตามปฏิทินตามลำดับ วันหมายถึง วันทำการ
                        และเวลาของวัน คือ เวลามาตรฐานของกรินิช (GMT)
                        บวกแปดชั่วโมง ซึ่ง “เวลาทำการ” คือ เวลาแปด (8) โมงเช้า
                        ถึง สี่ (16) โมงเย็นในวันทำการ และ
                      </span>
                    </h3>
                    <h3>
                      <strong>2.1.6. </strong>
                      <span>
                        ลายลักษณ์อักษร หมายถึง การส่งโทรสาร แต่ยกเว้น อีเมล
                        ข้อความตัวอักษร (SMS)
                        และวิธีการสื่อสารแบบอื่นที่คล้ายกัน
                      </span>
                    </h3>
                    <h2>
                      <strong>2.2.</strong>
                      <span>
                        นโยบายความเป็นส่วนตัว ข้อกำหนดการใช้งาน และ
                        กฎกติกาและระเบียบข้อบังคับการเดิมพัน
                        ถือเป็นส่วนหนึ่งของข้อกำหนดและเงื่อนไขนี้
                        การอ้างอิงถึงข้อกำหนดและเงื่อนไขนี้รวม
                        เอกสารที่กล่าวถึงในตอนต้นด้วย
                      </span>
                    </h2>
                    <h2>
                      <strong>2.3</strong>.{' '}
                      <span>
                        ส่วนหัวและอักษรย่อที่ใช้ในเอกสารมีไว้เพื่อความสะดวกในการอ่านเท่าันั้น
                        และจะไม่มีผลต่อการตีความเอกสาร
                      </span>
                    </h2>
                    <h2>
                      <strong>2.4.</strong>
                      <span>
                        ในเอกสาร วลีใดๆ ที่เริ่มต้นด้วยคำว่า รวม รวมถึง รวมทั้ง
                        และ เช่น ให้ตีความ ว่าเป็นการแสดงตัวอย่างประกอบ
                        และจะไม่จำกัดความหมายของคำที่อยู่ก่อนหน้า
                      </span>
                    </h2>
                    <h2>
                      <strong>2.5.</strong>
                      <span>
                        เอกสารได้เขียนขึ้นเป็นภาษาอังกฤษ ซึ่งเป็นภาษาต้นฉบับ
                        ถ้ามีการแปลเอกสารไปเป็น ภาษา อื่นใด
                        ให้ยึดความในภาษาอังกฤษเป็นหลัก
                        ถ้ามีความแตกต่างกันระหว่างเอกสาร และเอกสาร ประกอบ
                        หรือเอกสารอื่นใดที่รวมเข้าไว้เป็นการอ้างอิง
                        ให้ยึดตามข้อกำหนดและเงื่อนไขนี้ เป็นหลักทุกครั้ง{' '}
                      </span>
                    </h2>
                    <h1>3. การบังคับใช้และการยอมรับ</h1>
                    <h2>
                      <strong>3.1.</strong>
                      <span>
                        จะถือว่า "ลูกค้า" ได้ยอมรับเอกสาร เมื่อเลือกปุ่ม “ตกลง”
                        ในหน้านี้ และดำเนินการ เข้าสู่เว็บไซต์ต่อไป หรือ
                        โดยการเปิดบัญชีกับบริษัท หรือใช้บริการของบริษัท การตกลง
                        ยอมรับเอกสารและ/หรือ
                        เนื่องจากการที่ลูกค้าใช้บริการของบริษัท
                        จะทำให้ลูกค้ามีภาระ ผูกพันตามเอกสาร
                      </span>
                    </h2>
                    <h2>
                      <strong>3.2.</strong>
                      <span>
                        "บริษัทสงวนสิทธิ์ในการแก้ไขเอกสารตามที่เห็นสมควรเป็นครั้งคราวได้ตลอดเวลาโดยไม่ต้องแจ้งให้ลูกค้าทราบล่วงหน้า
                        อย่างไรก็ดีบริษัทจะพยายามอย่างสมเหตุสมผล เพื่อ
                        ประกาศแจ้้งทางเว็บไซต์เมื่อมีการเปลี่ยนแปลงใดๆ
                        ที่สำคัญเกิดขึ้นแก่เอกสารฉบับที่เป็น
                        ปัจจุบันมากที่สุดของเอกสารจะปรากฏอยู่บนเว็บไซต์
                      </span>
                    </h2>
                    <h2>
                      <strong>3.3.</strong>
                      <span>
                        ลูกค้ามีหน้าที่ตรวจสอบเอกสารบนเว็บไซต์ตามกำหนดเวลาทุกครั้งที่เข้าสู่เว็บไซต์
                        เพื่อที่ ลูกค้า จะยังคงเห็นด้วยกับเว็บไซต์ต่อไป
                        การที่ลูกค้าใช้เว็บไซต์ และ/หรือบริการ ของบริษัทต่อไป
                        จะถือเป็นการยอมรับเอกสารโดยปราศจากเงื่อนไข และไม่สามารถ
                        เพิกถอนได้ รวมทั้งการเปลี่ยนแปลงใดๆ ในเอกสาร
                        เงินเดิมพันที่ได้รับ ยอมรับ บันทึก
                        และสะสางแล้วจะอยู่ภายใต้เอกสารที่เผยแพร่ทางเว็บไซต์
                        และการเปลี่ยนแปลงใดๆ ดังกล่าว ณ เวลาที่ได้รับ ยอมรับ
                        บันทึก และสะสางเงินเดิมพันนั้นโดยบริษัท
                      </span>
                    </h2>
                    <h1>4. ข้อมูลประจำตัวลูกค้าและบัญชี</h1>
                    <h2>
                      <strong>4.1 </strong>
                      <span>
                        ในการใช้บริการของบริษัทและเว็บไซต์
                        ขั้นแรกบุคคลจะต้องเปิดบัญชีกับบริษัท (“บัญชีลูกค้า”)
                        โดยให้ข้อมูลที่ถูกต้องเป็นจริงต่อไปนี้เป็นอย่างน้อย
                        (“ข้อมูลประจำตัว ลูกค้า”)
                        ดังที่กำหนดโดยบริษัทตามวิจารณญาณเบ็ดเสร็จ
                        ของบริษัทแต่เพียงผู้เดียว เป็นครั้งคราว และตลอดเวลา
                      </span>
                    </h2>
                    <h3>
                      <strong>4.1.1. </strong>
                      <span>
                        ชื่อทางกฎหมายทั้งหมดตามที่ปรากฏในเอกสารประจำตัวที่ี่ออกให้โดยรัฐบาล{' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>4.1.2. </strong>
                      <span>
                        การกำหนดเอกสารประจำตัวที่ออกโดยรัฐบาลรวมทั้งหมายเลข
                      </span>
                    </h3>
                    <h3>
                      <strong>4.1.3. </strong>
                      <span>ที่อยู่จริงและที่อยู่ปัจจุบัน</span>
                    </h3>
                    <h3>
                      <strong>4.1.4. </strong>
                      <span>สัญชาติ</span>
                    </h3>
                    <h3>
                      <strong>4.1.5.</strong>
                      <span>วันเดือน ปี เกิด</span>
                    </h3>
                    <h3>
                      <strong>4.1.6. </strong>
                      <span>ที่อยู่อีเมลปัจจุบันที่ัถูกต้อง</span>
                    </h3>
                    <h3>
                      <strong>4.1.7. </strong>
                      <span>หมายเลขโทรศัพท์ปัจจุบันที่ถูกต้อง</span>
                    </h3>
                    <h2>
                      <strong>4.2 </strong>
                      <span>
                        ลูกค้ายอมรับว่าได้อ่านและเห็นด้วยกับนโยบายความเป็นส่วนตัวแล้ว
                        และตกลงว่า:
                      </span>
                    </h2>
                    <h3>
                      <strong>4.2.1. </strong>
                      <span>
                        ได้ยินยอมให้มีการประมวลผลและนำข้อมูลส่วนตัวไปใช้
                        ตามนโยบายความเป็นส่วนตัวนี้{' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>4.2.2. </strong>
                      <span>
                        บริษัท
                        สามารถเปิดเผยข้อมูลส่วนตัวของลูกค้าให้กับบุคคลภายนอกใดๆ
                        โดยไม่ต้องได้รับ คำยินยอม
                        เป็นลายลักษณ์อักษรจากลูกค้าล่วงหน้า
                        ในกรณีที่ศาลที่ถูกต้องตามกฎหมาย
                        หรือรัฐบาลมีคำสั่งขอข้อมูลดังกล่าว
                        ในระหว่างการสืบสวนการฟอกเงินโดยบริษัท หรือโดย
                        บุคคลภายนอกใดๆ และเมื่อบริษัท
                        มีเหตุอันควรให้สงสัยได้ว่าบัญชีลูกค้าหรือลูกค้า มีส่วน
                        เกี่ยวข้องกับการทำธุรกรรมผิดกฎหมาย
                        แม้เมื่อไม่เกี่ยวข้องกับบริษัทก็ตาม และ
                      </span>
                    </h3>
                    <h3>
                      <strong>4.2.3. </strong>
                      <span>
                        บริษัท อาจประมวลผล ใช้ ถ่ายโอน
                        หรือจัดการกับข้อมูลส่วนตัวของลูกค้า
                        ทั่วโลกด้วยวิธีใดก็ได้ที่บริษัทเลือก
                      </span>
                    </h3>
                    <h2>
                      <strong>4.3.</strong>
                      <span>
                        เมื่อยอมรับเอกสาร เปิดบัญชีลูกค้า
                        และใช้บริการของบริษัทแล้วเท่านั้น จึงจะถือว่าบุคคล
                        เป็นลูกค้าของบริษัท
                        บริษัทสงวนสิทธิ์ในการยอมรับหรือปฏิเสธ
                        ลูกค้าได้ตลอดเวลาไม่ว่าด้วย เหตุผลใดก็ตาม
                        กิจกรรมหรือการทำรายการใดๆ โดยบุคคลที่ไม่ถือเป็นลูกค้า
                        จะไม่มีผลหรือเป็นโมฆะ
                      </span>
                    </h2>
                    <h2>
                      <strong>4.4.</strong>
                      <span>
                        เป็นความรับผิดชอบของลูกค้าที่จะตรวจสอบให้แน่ใจว่ารหัสลูกค้าเป็นปัจจุบันอยู่เสมอกับทางบริษัท
                        ลูกค้าต้องให้ข้อมูลที่เป็นจริงเมื่อเปิดบัญชีหรือปรับปรุงบัญชีลูกค้า
                        หากไม่สามารถ ทำี้ได้
                        จะถือว่าลูกค้าได้ละเมิดข้อกำหนดและเงื่อนไขนี้และบัญชีจะถูกปิดทันที
                        รวมทั้ง ถูกริบเงินทั้งหมด{' '}
                      </span>
                    </h2>
                    <h2>
                      <strong>4.5.</strong>
                      <span>
                        เมื่อได้รับการร้องขอจากบริษัท
                        ลูกค้าตกลงที่จะจัดเตรียมเอกสารใดๆ ให้แก่บริษัท
                        ในทันทีเพื่อตรวจสอบความถูกต้องของข้อมูลประจำตัวลูกค้า
                      </span>
                    </h2>
                    <h2>
                      <strong>4.6.</strong>
                      <span>
                        ลูกค้ามอบสิทธิให้บริษัทใช้วิธีการใดๆ
                        ที่ถูกกฎหมายที่เห็นว่าจำเป็นในการตรวจสอบความ
                        ถูกต้องของข้อมูลประจำตัวลูกค้า{' '}
                      </span>
                      .
                    </h2>
                    <h2>
                      <strong>4.7.</strong>
                      <span>
                        เมื่อได้รับข้อมูลรหัสลูกค้าจากลูกค้าตามที่กำหนดไว้ใน 4.1
                        ที่นี้แล้ว บริษัท จะกำหนด ชื่อผู้ใช้และรหัสผ่าน
                        (เรียกรวมกันว่า “รหัสเข้าสู่ระบบ”)
                        ซึ่งจะอยู่ภายใต้ข้อกำหนด และเงื่อนไขต่อไปนี้
                      </span>
                    </h2>
                    <h3>
                      <strong>4.7.1. </strong>
                      <span>
                        มีเพียงบริษัทเท่านั้นที่สามารถกำหนดชื่อผู้ใช้ให้
                        และห้ามไม่ให้แก้ไขหรือเปลี่ยนแปลงชื่อผู้ใช้
                        ไม่ว่าเมื่อใดก็ตาม{' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>4.7.2. </strong>
                      <span>
                        รหัสเข้าสู่ระบบเป็นข้อมูลส่วนตัวของลูกค้า
                        และสามารถใช้โดยลูกค้าเพียงผู้เดียวเท่านั้น
                        การรักษาความลับของรหัสเข้าสู่ระบบเป็นหน้าที่ของลูกค้า
                        ลูกค้าจะรับผิดชอบอย่างเต็มที่
                        ต่อกิจกรรมและการทำรายการใดๆ
                        บนเว็บไซต์ที่ดำเนินการด้วยรหัสเข้าสู่ระบบของตน
                        รวมทั้งการใช้รหัสเข้าสู่ระบบในทางที่ผิด
                        และ/หรือการเปิดเผยรหัสเข้าสู่ระบบให้แก่ผู้ใด
                        โดยไม่ได้รับอนุญาต
                        ลูกค้าต้องเปลี่ยนรหัสผ่านของรหัสเข้าสู่ระบบเป็นประจำอย่างน้อย
                        เดือนละครั้งเพื่อลดความเป็นไปได้ในการนำไปใช้ในทางที่ผิด
                        การละเมิดหรือการประนี ประนอมรหัสเข้าสู่ระบบ
                        ทั้งที่เกิดขึ้นแล้วที่สงสัยว่าจะเกิดขึ้น
                      </span>
                    </h3>
                    <h3>
                      <strong>4.7.3. </strong>
                      <span>
                        ลูกค้าต้องแจ้งให้บริษัททราบเป็นลายลักษณ์อักษรทันทีหากมีการนำไปใช้ในทางที่ผิด
                        การละเมิดหรือการประนีประนอมรหัสเข้าสู่ระบบ
                        ทั้งที่เกิดขึ้นแล้ว หรือที่สงสัยว่าจะเกิดขึ้น
                        ลูกค้าจะยังคงรับผิดชอบกิจกรรมและการทำรายการทั้งหมดที่ดำเนินการด้วยรหัสเข้าสู่ระบบของลูกค้าต่อไปจนกว่าบริษัท
                        จะได้รับหนังสือแจ้งดังกล่าว
                        เมื่อได้รับหนังสือแจ้งดังกล่าวแล้ว
                        บริษัทจะระงับบัญชีลูกค้าทันทีโดยไม่รีรอ "บริษัท"
                        จะไม่รับผิดชอบ หรือรับผิด ในทาง หรือเรื่องใดก็ตาม
                        ต่อความล่าช้าในการระังับบัชีผู้ใช้ดังกล่าว
                        เพื่อหลีกเลี่ยงข้อสงสัย
                        จนกว่าบริษัทจะได้รับหนังสือแจ้งดังกล่าว
                        และบัญชีลูกค้าถูกระงับ กิจกรรมและการทำรายการ ต่างๆ
                        ผ่านบัญชีลูกค้าจะยังคงถือว่ามีผลต่อไป
                        เมื่อได้รับหนังสือแจ้งดังกล่าวและบัญชีลูกค้า
                        ถูกระงับแล้วเท่านั้น ที่กิจกรรมและการทำรายการต่างๆ
                        ผ่านบัญชีลูกค้าจะถือว่าไม่มีผล{' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>4.7.4. </strong>
                      <span>
                        ถ้า "บริษัท"
                        มีเหตุผลให้เชื่อได้ว่ามีความเป็นไปได้ที่จะเกิดการละเมิดความปลอดภัย
                        หรือการใช้เว็บไซต์ในทางที่ผิด หรือการใช้ผิดวิธีใดๆ
                        การละเมิดหรือประนีประนอมรหัส เข้าสู่ระบบของลูกค้า
                        ทั้งที่เกิดขึ้นแล้ว หรือสงสัยว่าจะเกิดขึ้น บริษัท
                        อาจระงับบัญชีลูกค้า
                        และ/หรือเปลี่ยนแปลงรหัสผ่านของรหัสเข้าสู่ระบบ
                        โดยใช้วิจาณญาณเบ็ดเสร็จ แต่เพียง ผู้เดียวของบริษัท
                        โดยแจ้งให้ลูกค้าทราบถึงการดังกล่าว
                        คำกล่าวข้างต้นเป็นมาตรการป้องกัน เท่านั้น
                        โดยไม่ได้นำเข้าความรับผิดหรือข้อผูกมัดใดๆ
                        ก็ตามสำหรับบริษัท หากบริษัท
                        เลือกที่จะไม่ใช้สิทธิในความเป็นไปได้นี้{' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>4.7.5. </strong>
                      <span>
                        "บริษัทอาจสุ่มตรวจความปลอดภัยหรือสุ่มตรวจบัญชีลูกค้า
                        ในเวลาใดก็ได้ เป็นครั้งคราว ลูกค้ายอมรับว่า
                        บริษัทมีสิทธิดำเนินการตรวจสอบดังกล่าว
                        และขอข้อมูลเพิ่มเติม และ/หรือเอกสารจากลูกค้า
                      </span>
                    </h3>
                    <h3>
                      <strong>4.7.6. </strong>
                      <span>
                        บริษัทสงวนสิทธิ
                        ตามวิจารณญาณเบ็ดเสร็จแต่เพียงผู้เดียวของบริษัท
                        โดยไม่ต้องอธิบายเพิ่ม เติมต่อลูกค้า
                        ในเรื่องเกี่ยวกับบัญชีลูกค้า{' '}
                      </span>
                    </h3>
                    <h4>
                      <strong>4.7.6.1. </strong>
                      <span>
                        เพื่อทำการระงับ หากบริษัทมีความเชื่ออย่างสมเหตุสมผลว่า
                        การใช้บัญชี ลูกค้าต่อไป จะสร้างความเสียหายที่ไม่คาดคิด
                        หรือการสูญเสียประเภทใดๆ แก่ลูกค้า หรือแก่บริษัท{' '}
                      </span>
                    </h4>
                    <h4>
                      <strong>4.7.6.2. </strong>
                      <span>
                        เพื่อทำการระงับ หากในระหว่างการสืบสวนการละเมิดเอกสาร
                      </span>
                    </h4>
                    <h4>
                      <strong>4.7.6.3. </strong>
                      <span>
                        เพื่อทำการปิด หากลูกค้าทำการละเมิดเอกสาร
                        ตามที่กำหนดไว้โดยบริษัท
                        ตามวิจารณญาณเบ็ดเสร็จแต่เพียงผู้เดียวของบริษัท
                        ในกรณีดังกล่าว "บริษัท"
                        ขอสงวนสิทธิ์และการเยียวยาแก้ไขแก่ลูกค้าภายใต้เอกสาร
                        และตามกฎหมาย
                        และขอสงวนสิทธิ์ที่จะขอเรียกเงินจากลูกค้าเพื่อเป็นการรับประกันข้อผูกมัดของลูกค้าในเรื่องดังกล่าว
                        และ/หรือความรับผิดใดๆ ที่เกิดขึ้นจากการละเมิด เอกสาร
                        ดังกล่า่วโดยลูกค้า
                      </span>
                    </h4>
                    <h4>
                      <strong>4.7.6.4.</strong>
                      <span>
                        เพื่อทำการปิด
                        หากบริษัทมีเหตุให้สงสัยได้ว่าการทำรายการผิดกฎหมาย
                        และ/หรือการที่ลูกค้ามีส่วนเกี่ยวข้องกับกิจกรรมผิดกฎหมายแม้เมื่อไม่เกี่ยว
                        ข้องกับบริษัทก็ตาม
                      </span>
                    </h4>
                    <h4>
                      <strong>4.7.6.5. </strong>
                      <span>
                        เพื่อทำการปิด หากบัญชีลูกค้าไม่ได้ใช้งานเป็นเวลาสาม (3)
                        เดือนติดต่อกัน หรือนานกว่า ในกรณีดังกล่าว
                        ลูกค้าตกลงที่จะให้ริบเงินและการเรียกร้องเงิน ใดๆ
                        ทั้งหมดจากบริษัท
                      </span>
                    </h4>
                    <h4>
                      <strong>4.7.6.6.</strong>
                      <span>
                        เพื่อทำการปิด หาก ลูกค้า บุคคล
                        กลุ่มลูกค้าหรือกลุ่มบุคคลที่สมคบคิดกัน
                        หรือรวมหัวกันหลอกลวง และ/หรือฉ้อฉลบริษัท
                      </span>
                    </h4>
                    <h4>
                      <strong>4.7.6.7</strong>
                      <span>
                        เพื่อทำการปิดหรือระงับ (แล้วแต่บริษัทจะเลือก)
                        ได้ตลอดเวลาโดย มีเหตุผลหรือไม่มีเหตุผลก็ได้
                        ในกรณีดังกล่าว รายการที่คงค้างจะได้รับ การยอมรับ
                        (โดยมีข้อแม้ว่าต้องเป็นไปตามเอกสารนี้) และเงินใดๆ
                        ที่เป็นของลูกค้าจะได้รับการชำระคืน
                      </span>
                    </h4>
                    <h3>
                      <strong>4.7.7. </strong>
                      <span>
                        เมื่อบัญชีลูกค้าถูกระงับ บริษัทขอสงวนสิทธิ์
                        โดยไม่รับผิดใดๆ สำหรับบริิษัท (รวมถึง ผลประโยชน์
                        การสูญเสียเงินออม หรือสัญญา) เพื่ออายัดเงินใดๆ
                        ที่ต้องจ่ายแก่ลูกค้า หยุดการใช้บัญชีลูกค้า
                        และการทำรายการใดๆ ที่เกี่ยวเนื่องกัน ยกเลิกการเดิมพัน
                        ทั้งหมดที่รอการยอมรับ ใช้ข้อมูลใดๆ ที่มีอยู่สำหรับบริษัท
                        และวิธีการสืบสวนใดๆ ที่บริษัทเลือก
                        (รวมทั้งการติดต่อกับบุคคลภายนอก สำนักงานรัฐบาล
                        และ/หรือการขอเอกสาร และข้อมูลจากลูกค้า)
                        เพื่อทำการสอบสวนเหตุการณ์ที่ทำให้เกิดข้อสงสัย
                        ซึ่งลูกค้ายินยอม และตกลงที่จะร่วมมืออย่างเต็มที่
                        การไม่ร่วมมือจากลูกค้าจะส่งผลให้ลูกค้าถูกปิดบัญชี
                      </span>
                    </h3>
                    <h3>
                      <strong>4.7.8. </strong>
                      <span>
                        เมื่อบัญชีลูกค้าถูกปิด บริษัทขอสงวนสิทธิ์โดยไม่รับผิดใดๆ
                        ก็ตามสำหรับบริษัท (รวมถึง ผลประโยชน์ การสูญเสียเงินออม
                        หรือสัญญา) เพื่ออายัดเงินที่ต้องจ่ายให้แก่ลูกค้าเป็น
                        การรับประกันข้อผูกมัดหรือความรับผิดของลูกค้าตามเอกสารนี้
                        หรือเมื่อสงสัย ว่ามีกิจกรรม ผิดกฎหมาย
                        หยุดการใช้บัญชีลูกค้าหรือการทำรายการใดๆ
                        ที่เกี่ยวข้องกับบัญชีดังกล่าว ยกเลิกการเดิมพันใดๆ
                        ที่รอการยอมรับ ใช้ข้อมูลใดๆ ที่มีอยู่สำหรับบริษัท
                        และวิธีการสืบสวนใดๆ ที่บริษัทเลือก
                        (รวมถึงการติดต่อบุคคลที่สาม สำนักงานรัฐบาล และ/หรือ
                        การขอเอกสารและข้อมูลจากลูกค้า)
                        เพื่อทำการสอบสวนเหตุการณ์ที่ก่อให้เกิด การปิด
                        ซึ่งลูกค้ายินยอมและตกลงที่จะร่วมมืออย่างเต็มที่{' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>4.7.9. </strong>
                      <span>
                        ลูกค้าอาจปิดบัญชีลูกค้าที่เปิดไว้กับบริษัทได้ตลอดเวลา
                        โดยการแจ้งเป็นลายลักษณ์อักษร ไปยังบริษัท
                        เมื่อส่งหนังสือแจ้งดังกล่าว
                        ลูกค้่าต้องหยุดใช้เว็บไซต์ทันที
                        หลังจากบริษัทได้รับหนังสือแจ้งจากลูกค้าถึงการปิดบัญชีแล้วเท่าั้นั้น
                        ที่รายการต่างๆ ที่ทำกับบัญชีลูกค้าจะุืถือว่าไม่มีผล
                        และบัญชีลูกค้าได้ปิดลงแล้ว ลูกค้าจะยังคงรับผิดต่อ
                        กิจกรรม และรายการใดๆ ต่อไป จนกว่าบัญชีลูกค้าจะปิด
                        เมื่อปิดบัญชีลูกค้าแล้ว บริษัท จะคืนเงิน (ถ้ามี)
                        ที่ครบกำหนดให้แก่ลูกค้า{' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>4.7.10. </strong>
                      <span>
                        ลูกค้ามีหน้าที่ต้องรักษา บัญชีลูกค้าไว้ตลอดเวลา
                        บัญชีลูกค้าจะถือว่าใช้งานอยู่ถ้า
                        ลูกค้าได้เข้าสู่ระบบและใช้บริการของบริษัท
                        ด้วยบัญชีลูกค้าในระยะเวลาหนึ่ง (1) เดือน
                        มิเช่นนั้นจะถือว่าบัญชีลูกค้าไม่ได้ใช้งาน
                      </span>
                    </h3>
                    <h2>
                      <strong>4.8. </strong>
                      <span>
                        บุคคลสามารถเปิดหรือถือบัญชีลูกค้าได้มากกว่าหนึ่งบัญชี
                        บัญชีลูกค้าเพิ่มเติมใดๆ ที่เปิดจะถูก บริษัทปิด และ
                        (ถ้ามี) เงินใดๆ จะคืนให้ลูกค้า
                        หรือบัญชีทั้งหมดดังกล่าวอาจถือ เป็นบัญชีร่วม
                        และรวมเข้าด้วยกันโดยบริษัท ไม่ว่าในกรณีใด
                        จะขึ้นอยู่กับวิจารณญาณเบ็ดเสร็จ
                        ของบริษัทแต่เพียงผู้เดียวเท่านั้น
                        ลูกค้าตกลงว่าบริษัทจะใช้วิธีการใดๆ ที่เห็นสมควรใน
                        ารตัดสินใจว่าบัญชีตั้งแต่สองบัญชีขึ้นไปเป็นของลูกค้ารายเดียวกัน
                      </span>
                    </h2>
                    <h2>
                      <strong>4.9. </strong>
                      <span>
                        บริษัทอาจเปิดบัญชีลูกค้าชั่วคราวสำหรับจุดประสงค์ด้านการตลาดหรือการสาธิต
                        บัญชี หล่านี้จะมีอายุตามเวลาที่กำหนดไว้ล่วงหน้า
                        ซึ่งไม่เกินสามสิบ (30) วัน หลังจาก ช่วงเวลาดังกล่าว
                        บัญชีลูกค้าชั่วคราวจะถูกบริษัทปิด
                        หรือลูกค้าจะต้องมีคุณสมบัติตามเกณฑ์ ที่กำหนด
                        และเปิดบัญชีลูกค้าแบบปกติ{' '}
                      </span>
                    </h2>
                    <h1>5. การรับรองของลูกค้าในเบื้องต้น</h1>
                    <h2>
                      <strong>5.1.</strong>
                      <span>
                        นอกเหนือจากการรับรองหรือการรับประกันอื่นใดที่รวมอยู่ในเอกสารนี้
                        ลูกค้ายอมรับ รับรอง และ รับประกัน โดยไม่มีีเงื่อนไข
                        และโดยเพิกถอนไม่ได้ และมีข้อผูกมัด
                        โดยไม่มีข้อสงวนหรือข้อจำกัด (แล้วแต่กรณี) ว่าลูกค้า
                      </span>
                    </h2>
                    <h3>
                      <strong>5.1.1.</strong>
                      <span>
                        อายุ 18 ปี
                        หรือบรรลุนิติภาวะตามกฎหมายในเขตอำนาจศาลของลูกค้า
                        ซึ่งลูกค้าพำนักอยู่ตาม ปกติ หรือแหล่งพำนักปัจจุบัน
                        แล้วแต่อย่างใดจะสูงกว่า
                      </span>
                    </h3>
                    <h3>
                      <strong>5.1.2.</strong>
                      <span>
                        มีสภาพจิตสมบูรณ์ที่จะรับผิดชอบการกระทำของลูกค้า
                        และปฏิบัติตามเอกสารนี้ โดยไม่ต้อง บีบบังคับ
                      </span>
                    </h3>
                    <h3>
                      <strong>5.1.3.</strong>
                      <span>
                        เข้าใจว่าการพนันบนอินเทอร์เน็ตหรืออุปกรณ์สื่อสารระยะไกลอื่นๆ
                        หรือการพนันโดยทั่วไป
                        อาจผิดกฎหมายในเขตอำนาจศาลที่ลูกค้าพำนักอยู่ ลูกค้ามีความ
                        รับผิดชอบแต่เพียง
                        ผู้เดียวที่จะปฏิบัติตามกฎหมายในประเทศหรือกฎหมายท้องถิ่นที่บังคับใช้ได้
                        ก่อนที่จะปิด บัญชีลูกค้า โดยใช้บริการของบริษัท
                        หรือเข้าสู่เว็บไซต์{' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>5.1.4.</strong>
                      <span>จะไม่เข้าสู่เว็บไซต์ ไม่ว่าในเวลาใด</span>
                    </h3>
                    <h4>
                      <strong>5.1.4.1.</strong>
                      <span>
                        จากในเขตอำนาจศาลที่ห้ามการพนันหรือการพนันระยะไกล
                      </span>
                    </h4>
                    <h4>
                      <strong>5.1.4.2.</strong>
                      <span>
                        ถ้าลูกค้าเป็นพลเมืองของรัฐประเทศที่ห้ามไม่ให้พลเมืองของตนเล่นการพนัน
                        หรือการพนันระยะไกล (ไม่ว่าจะพำนักอยู่ ณ ที่ใดในปัจจุบัน)
                        และ
                      </span>
                    </h4>
                    <h4>
                      <strong>5.1.4.3.</strong>
                      <span>
                        ถ้าลูกค้าเป็นพลเมืองหรือผู้อาศัยใน
                        หรืออยู่ในเขตอำนาจศาลที่ห้ามใดๆ หรือเมืองขึ้น
                        หรืออาณาเขตใต้ปกครองที่สั่งห้ามใดๆ{' '}
                      </span>
                    </h4>
                    <h3>
                      <strong>5.1.5.</strong>
                      <span>
                        ว่าบริษัทให้บริการจากเขตอำนาจศาสและ/หรือประเทศที่ระบุไว้ในที่นี้
                        ดำเนินการ ถูกกฎหมาย
                        และได้รับอนุญาตให้เปิดให้บริการดังกล่าว
                        ซึ่งสามารถกระทำได้ตามกฎหมาย และว่าบริษัทได้
                        พยายามที่จะไม่เชิญชวนหรือทำธุรกิจจาก
                        หรือในเขตอำนาจศาลที่ห้าม หรือกับลูกค้าใน
                        หรือจากเขตอำนาจศาลที่ห้าม
                        (ถ้าลูกค้ามาจากหรืออยู่ในเขตอำนาจศาลที่ห้าม){' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>5.1.6.</strong>
                      <span>
                        ไม่ได้รับการเชิญชวนหรือเสนอให้บริการของบริษัทโดยบริษัท
                        และลูกค้าเองที่เป็นผู้เสาะหาและขอบริการของบริษัทตามเอกสาร{' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>5.1.7.</strong>
                      <span>
                        ไม่ทราบถึงสิ่งพิมพ์หรือสื่อทางการตลาดใดๆ
                        จากบริษัทในเขตอำนาจศาลของถิ่นที่พำนัก
                        หรือสถานที่อยู่ปัจจุบัน{' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>5.1.8.</strong>
                      <span>
                        จะใช้เว็บไซต์ตามความเสี่ยงของตนเองแต่เพียงผู้เดียว
                        โดยเข้าใจว่าอาจสูญเสียเงิน จากการ ใช้บริการของบริษัท
                        และยอมรับผิดชอบต่อการสูญเสียดังกล่าวอย่างเต็มที่
                        (ซึ่งถือว่าสามารถ รู้ล่วงหน้าได้){' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>5.1.9.</strong>
                      <span>
                        จะไม่แทนตัวเองว่าเป็นตัวแทนหรือบริษัทในเครือของบริษัท
                        และไม่ทำการตลาด โฆษณา ประชาสัมพันธ์ หรือส่งเสริมบริษัท
                        หรือบริการของบริษัท โดยไม่ได้รับความยินยอม
                        เป็นลายลักษณ์อักษรจากบริษัท
                      </span>
                    </h3>
                    <h3>
                      <strong>5.1.10.</strong>
                      <span>
                        เราจะไม่ (หรือแม้แต่พยายามที่จะ) โจมตี โจรกรรม
                        กระทำการที่เป็นอันตราย
                        ทำการเปลี่ยนแปลงโดยไม่ได้รับอนุญาต
                        หรือใช้รหัสที่เป็นอันตรายไม่ว่ารูปแบบใดๆ ต่อเว็บไซต์
                        ด้วยเหตุดังกล่าว ข้อจำกัดของลูกค้าดังกล่าวจะไม่รวมถึง
                        (ก) การทำงานด้านวิศวกรรม หรือ การแปลงผลลับ
                        (ทั้งหมดหรือบางส่วน) ซอฟต์แวร์ที่จัดไว้ให้ผ่านเว็บไซต์
                        หรือ (ข) ทำสำเนา แก้ไข ผลิตซ้ำ ส่งต่อ การเปลี่ยนแปลง
                        หรือ แจกจ่ายไม่ว่าทั้งหมดหรือส่วนหนึ่งส่วนใดของเว็บไซต์
                        หรือเนื้อหา หรือข้อมูลอื่นใดที่อยู่ในนั้น หรือ (ค)
                        ใช้หรือเปิดเผยข้อมูลโดยเว็บไซต์และ/หรือบัญชีลูกค้า
                        ให้แก่ผู้ใดโดยไม่ได้รับอนุญาต หรือ (ง)
                        ต่อต้านบทบัญญัติใดๆ ของเอกสารนี้ หรือ (จ) หลอกลวง ฉ้อโกง
                        ใช้อุบาย ให้ข้อมูลผิด หรือฉ้อฉลบริษั่ทด้วยวิธีการใดๆ
                        ผ่านการใช้หรือใช้บริการของบริษัทหรือเว็บไซต์ในทางที่ผิด{' '}
                        <span className='style1'>
                          หากบริษัทสงสัยว่าลูกค้าได้ฝ่าฝืนข้อกำหนดในบทบัญญัตินี้
                          บริษัทจะมีสิทธิ นอกเหนือไปจากการเยียวยาอื่นใด
                          ที่จะระงับบัญชีลูกค้าเพื่อดำเนินการสืบสวน
                          ซึ่งถ้าได้รับการยืนยันว่าลูกค้าไม่ปฏิบัติตามข้อกำหนดของบทบัญญัตินี้
                          บริษัท โดยวิจารณญาณเบ็ดเสร็จแต่เพียงผู้เดียวของบริษัท
                          สามารถปิดบัญชีลูกค้า และริบเงินใดๆ จากลูกค้า{' '}
                        </span>
                      </span>
                    </h3>
                    <h3>
                      <strong>5.1.11.</strong>
                      <span>
                        จะไ่ม่อนุญาตหรือให้สิทธิบุคคลอื่นใดหรือบุคคลภายนอก
                        (รวมถึงโดยไม่มีข้อจำกัดอยู่ที่ ผู้เยาว์)
                        ใช้บริการของบริษัท ใช้ บัญชีลูกค้า
                        หรือยอมรับเงินรางวัลในนามของตน{' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>5.1.12.</strong>
                      <span>
                        จะรายงานทันทีโดยไม่รีรอถึงเงินรางวัลดังกล่าวแก่เ้จ้าหน้าที่ที่เหมาะสมหากลูกค้าพำนักในเขตอำนาจศาลที่เงินรางวัลดังกล่าวจะต้องเสียภาษี
                        หรือต้องได้รับการเปิดเผยตามกฎหาย
                        และบริษัทจะไม่รับผิดต่อการไม่กระทำการดังกล่าวของลูกค้า
                        ไม่ว่าในกรณีใดทั้ืงสิ้น
                      </span>
                    </h3>
                    <h3>
                      <strong>5.1.13.</strong>
                      <span>
                        จะชดใช้ค่าเสียหายเต็ม ปกป้องและไม่ถือว่าบริษัท
                        และผู้ถือหุ้น ผู้อำนวยการ เจ้าหน้าที่ พนักงาน ที่ปรึกษา
                        ตัวแทน บริษัทในเคือ และผู้จัดหาสินค้า และตัวแทนอื่นๆ
                        (“คู่สัญญาเพิ่มเติม”) ต้องจ่ายค่าเสียหา่ย รับผิดชอบ
                        หรือไม่ทำให้เสียหาย สูญเสีย เสียค่าใช้จ่าย
                        ค่าทนายความอื่นใดทั้งหมด (ไม่ว่าจะเป็นทฤษฎีกฎหมายใด)
                        อันเกิดจากการที่ลูกค้าละเมิดเอกสารนี้
                        หรือความรับผิดอื่นๆ ที่เกิดจากเอกสารหรือการ
                        ที่ลูกค้าใช้บริการของบริษัท{' '}
                      </span>
                    </h3>
                    <h2>
                      <strong>5.2.</strong>
                      <span>
                        ลูกค้าตกลงว่าการรับประกันใดๆ ทั้งหมด
                        และการรับรองที่กระทำในที่นี้ เป็นความจริง และถูกต้อง
                        และจะปฏิบัติตามและรักษาไว้ซึ่งการรับรองและการรับประกันทุกข้อ
                        ในขณะที่ยัง คงมีสถานะเป็นลูกค้าของบริษัทที่มีบัญชีลูกค้า{' '}
                      </span>
                    </h2>
                    <h1>6. ข้อกำหนดการทำรายการ</h1>
                    <h2>
                      <strong>6.1.</strong>
                      <span>
                        บริษัทได้รับอนุญาตให้รับเงินเดิมพันผ่านอุปกรณ์สื่อสารระยะไกลทั่วโลก
                        ภายใต้ใบอนุญาตประกอบกิจการเกมการแ่ข่งขันเชิงโต้ตอบของบริษัท
                        ที่ออกให้โดย First Cagayan Leisure Resorts Corporation
                        และ Cagayan Economic Zone Authority
                        ในนามของรัฐบาลฟิลิปปินส์
                        เงินเดิมพันทั้งหมดจะถือว่าได้วาง ยอมรับ และบันทึกไว้ใน
                        เขตอำนาจศาลของ Cagayan Special Economic Zone and
                        Freeport ในสาธารณรัฐ ฟิลิปปินส์{' '}
                      </span>
                    </h2>
                    <h2>
                      <strong>6.2.</strong>
                      <span>
                        การเดิมพันจะถือว่าวางแล้ว (“เดิมพันที่วางแล้ว”)
                        เมื่อได้กระทำโดยลูกค้าผ่าน ทางเว็บไซต์ ในอินเทอร์เน็ต
                        การเดิมพันจะถือว่ายอมรับและบันทึกไว้
                        (“เดิมพันที่ยอมรับแล้ว”) เมื่อได้
                        ยอมรับและบันทึกไว้ตามเอกสารนี้โดยเซิร์ฟเวอร์เกมของบริษัทเท่านั้น
                        การเดิมพัน จะถือว่า เสร็จสิ้น
                        (“เดิมพันที่เสร็จสิ้นแล้ว”) เมื่อได้ยอมรับและบันทึกไว้
                        และลูกค้าได้รับแจ้ง (ผ่านทาง
                        อีเมลที่บริษัทส่งไปให้ยังลูกค้าผ่านทางเว็บไซต์)
                        ถึงการยอมรับและการบันทึกดังกล่าวแล้ว{' '}
                      </span>
                    </h2>
                    <h2>
                      <strong>6.3.</strong>
                      <span>
                        บัญชีลูกค้่าจะต้องมีเงินเครดิตเหลืออยู่ในจำนวนเท่ากับ
                        หรืออย่างน้อย ในจำนวนขั้นต่ำ
                        สำหรับการเดิมพันในเหตุการณ์หรือตลาด
                        ลูกค้าจึงจะสามารถวางเดิมพันได้{' '}
                      </span>
                    </h2>
                    <h2>
                      <strong>6.4.</strong>
                      <span>
                        ลูกค้าไม่ได้รับอนุญาตให้ยกเลิกหรือเปลี่ยนแปลงเดิมพันที่ยอมรับแล้ว
                        และบริษัทไม่มี ข้อผูกมัด ให้ยกเลิกเดิมพันที่ยอมรับแล้ว{' '}
                      </span>
                    </h2>
                    <h2>
                      <strong>6.5.</strong>
                      <span>
                        เดิมพันจะยอมรับได้จนถึงวันครบกำหนดตามที่โฆษณาไว้
                        ซึ่งกว่าจะถึงตอนนั้น จะสามารถ
                        ยอมรับเดิมพันได้สำหรับเหตุการณ์หรือตลาดใดๆ{' '}
                      </span>
                    </h2>
                    <h2>
                      <strong>6.6.</strong>
                      <span>
                        บริษัทขอสงวนสิทธิ์ที่จะระงับการเดิมพันเหตุการณ์หรือตลาดใดๆ
                        เพิ่มเติมเป็นการชั่วคราว หรือไม่มีกำหนด ในเวลาใดก็ได้
                        โดยไม่ต้องแจ้งให้ลูกค้าทราบล่วงหน้า ตามวิจารณญาณ
                        เบ็ดเสร็จแต่เพียงผู้เดียวของบริษัท
                        เมื่อใดที่เหตุการณ์หรือตลาดถูกระงับ หรือสั่งห้ามการ
                        เข้าถึง ความพยายามใดๆ
                        ที่จะวางเดิมพันของลูกค้าจะถูกปฏิเสธ{' '}
                      </span>
                    </h2>
                    <h2>
                      <strong>6.7.</strong>
                      <span>
                        การเดิมพันจะถือว่าเป็นโมฆะ (“เดิมพันที่เป็นโมฆะ”) ถ้า
                      </span>
                    </h2>
                    <h3>
                      <strong>6.7.1.</strong>
                      <span>
                        ไม่ได้ส่งครบถ้วน รวมทั้ง
                        แต่ไม่จำกัดเพียงกรณีที่การส่งถูกขัดจังหวะหรือหยุดชะงักอันเนื่อง
                        มาจากปัญหาทางเทคนิคหรือการสื่อสารผิดพลาด{' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>6.7.2.</strong>
                      <span>ไม่ได้ยอมรับโดยบริษัทอันเนื่องจากขัดต่อเอกสาร</span>
                    </h3>
                    <h3>
                      <strong>6.7.3.</strong>
                      <span>
                        บริษัทมีเหตุอันเชื่อได้ว่าลูกค้าหรือบุคคลหรือกลุ่มบุคคลรวมหัวกัน
                        หรือสมคบคิดกันฉ้อฉล หรือหลอกลวงบริษัท{' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>6.7.4.</strong>
                      <span>
                        สถานการณ์และ/หรือข้อสันนิษฐานของบริษัทต่อการเปลี่ยนแปลงเหตุการณ์ใดเหตุการณ์หนึ่ง
                        โดยเฉพาะ{' '}
                      </span>
                    </h3>
                    <h3>
                      <strong>6.7.5.</strong>
                      <span>
                        ตลาดหรือเหตุการณ์ไม่ถูกระงับในเวลาที่เหมาะสม
                        ซึ่งจะกำหนดภายใ้ต้วิจารณญาณ
                        เบ็ดเสร็จแต่เพียงผู้เดียวของบริษัท
                      </span>
                    </h3>
                    <h3>
                      <strong>6.7.6.</strong>
                      <span>
                        ข้อผิดพลาดด้านการพิมพ์ ทางเทคนิค ที่ิเกิดจากมนุษย์
                        หรือที่เห็นชัดเจน ในการเปิดเผย ประกาศ
                        หรือใช้ข้อมูลการเดิมพัน{' '}
                      </span>
                    </h3>
                    <h2>
                      <strong>6.8.</strong>
                      <span>
                        บริษัทขอสงวนสิทธิ์ภายใต้วิจารณญาณเบ็ดเสร็จแต่เพียงผู้เดียวของบริษัท
                        โดยไม่จำเป็นต้องมีคำอธิบาย
                        หรือแจ้งให้ลูกค้าทราบล่วงหน้าในการปฏิเสธการเดิมพัน
                        หรือส่วนใดๆ ของการเดิมพันดังกล่าว{' '}
                      </span>
                    </h2>
                    <h2>
                      <strong>6.9.</strong>
                      <span>
                        ข้อมูลการเดิมพันทั้งหมดอาจมีการเปลี่ยนแปลงได้
                        แต่จะคงเดิมในเวลาที่ เดิมพันที่วางแล้ว กลายมาเป็น
                        เดิมพันที่ยอมรับแล้ว
                        เว้นแต่สำหรับการเดิมพันเกมบิลเลียดและการแ่ข่งม้า
                        ซึ่งข้อมูลจะคงเดิม
                        ในเวลาที่การแข่งบิลเลียดหรือการแข่งม้าดังกล่าวปิดลง
                        บริษัทขอสงวนสิทธิ์ภายใต้วิจารณญาณเบ็ดเสร็จแต่เพียงผู้เดียวของบริษัท
                        โดยไม่้้ต้องมีคำอธิบายหรือแจ้งลูกค้าล่วงหน้าถึงการเปลี่ยนแปลงข้อมูลการเดิมพัน
                        ไม่ว่าเวลาใดก็ตาม
                        เมื่อใดก็ตามที่มีการแสดข้อมูลการเดิมพันที่ไม่ถูกต้อง
                        ถูกละเว้น หรือทำให้เข้าใจผิด โดยผิดพลาด บริษัท
                        ขอสงวนสิทธิ์ภายใต้วิจารณญาณเบ็ดเสร็จ
                        แต่เพียงผู้เดียวของบริษัท
                        โดยไม่ต้องแจ้งให้ลูกค้าทราบล่วงหน้า ใน การทำให้เป็นโมฆะ
                        หรือการปฏิเสธการเดิมพันที่ได้รับผลกระทบใดๆ
                        และ/หรือแก้ไขข้อผิดพลาด การละเว้น
                        หรือการทำให้เข้าใจผิดใดๆ
                        บริษัทจะทำประกาศแจ้งอย่างชัดเจนบนเว็บไซต์หากเกิด
                        กรณีดังกล่าว
                      </span>
                    </h2>
                    <h2>
                      <strong>6.10.</strong>
                      <span>
                        จำนวนเดิมพันสูงสุดและต่ำสุดที่ลูกค้าสามารถวางได้ในตลาดหรือเหตุการณ์ใดๆ
                        แตกต่างกันขึ้นอยู่กับเหตุการณ์หรือตลาดนั้นๆ
                        และสามารถเปลี่ยนแปลงได้โดยไม่ต้องแจ้งให้ทราบ
                        ล่วงหน้าตามวิจารณญาณเบ็ดเสร็จแต่เพียงผู้เดียวของบริษัท
                        ลูกค้าจะปฏิบัติ
                        ตามจำนวนเดิมพันต่ำสุดและสูงสุดที่ประกาศไว้
                        รวมทั้งขีดจำกัดต่างๆ{' '}
                      </span>
                    </h2>
                    <h2>
                      <strong>6.11.</strong>
                      <span>
                        เงินรางวัลที่ชนะจะเพิ่มให้
                        และเงินที่แพ้จะถูกหักจากบัญชีของลูกค้า ภายหลังการยืนยัน
                        ผลขั้นสุดท้ายของเหตุการณ์จากแหล่งข้อมูลทางการที่เกี่ยวข้องและมีความเหมาะสม
                        ตามที่กำหนดโดยบริษัทตามวิจารณญาณเบ็ดเสร็จเพียงผู้เดียวของบริษัท{' '}
                      </span>
                    </h2>
                    <h2>
                      <strong>6.12.</strong>
                      <span>
                        ลูกค้าต้องตรวจสอบยอดเงินคงเหลือในบัญชีขณะเข้าสู่ระบบทุกครั้ง
                        หากเงินได้ถูกเพิ่ม หรือหักออกจากบัญชีลูกค้าโดยผิดพลาด
                        ลูกค้ามีหน้าที่ต้องแจ้งบริษัทถึงข้อผิดพลาด
                        ดังกล่าวในทันที โดยไม่ล่าช้า
                        ยอดเงินที่เพิ่มในบัญชีลูกค้าเนื่องจากความผิดพลาด
                        จะถือว่าไม่มีผล และต้องคืนให้กับบริษัท
                        ลูกค้าไม่สามารถจัดการกับเงินที่เพิ่ม
                        ไปยังบัญชีลูกค้าโดยผิดพลาดได้
                        และบริษัทขอสงวนสิทธิ์ที่จะยกเลิกรายการใดๆ
                        (รวมทั้งการเดิมพัน) ที่เกี่ยวข้องกับเงินดังกล่าว
                        ลูกค้าตกลงที่จะชดใช้ค่าเสียหายแก่บริษัท
                        ในการคืนเงินที่เพิ่มให้โดยผิดพลาดซึ่งลูกค้าได้นำไปใช้
                      </span>
                    </h2>
                    <h2>
                      <strong>6.13.</strong>
                      <span>
                        บริษัทขอสงวนสิทธิ์ตามวิจารณญาณเบ็ดเสร็จแต่เพียงผู้เดียวของบริษัท
                        โดยไม่ทำให้เกิดเป็นความรับผิดใดๆ ก็ตาม
                        อันเกี่ยวเนื่องกับเงินที่ชำระให้ลูกค้าเพื่่อ
                      </span>
                    </h2>
                    <h2>
                      <strong>6.14.</strong>
                      <span>
                        อายัดเงินในกรณีที่บัญชีลูกค้าถูกระงับ
                        มีข้อสงสัยว่าทำรายการผิดกฎหมาย หรือละเมิด เอกสารนี้
                        กำลังรอผลการสืบสวนจากเจ้าหน้าที่ของรัฐ หรือสถาบันการเงิน
                        และ/หรือ{' '}
                      </span>
                    </h2>
                    <h2>
                      <strong>6.15.</strong>
                      <span>
                        ระงับการใช้เงินในกรณีที่บัญชีลูกค้าถูกปิดเนื่องจากทำรายการผิดกฎหมาย
                        หรือละเมิดเอกสาร
                        และ/หรือตามที่เจ้าหน้าที่ของรัฐหรือสถาบันการเงินร้องขอ
                      </span>
                    </h2>
                    <h2>
                      <strong>6.16.</strong>
                      <span>
                        การจ่ายเงินใดๆ และทั้งหมดที่จ่ายให้กับลูกค้า รวมภาษี
                        ภาษี VAT หรือค่าธรรมเนียม ที่สามารถเรียกเก็บได้ใดๆ
                        ภาษีขายท้องถิ่น ในอัตราที่คงอยู่ ณ
                        วันที่ทำรายการดังกล่าว ยอดเงินใดๆ ที่ต้องจ่ายให้บริษัท
                        จะต้องไม่รวมจำนวนที่ถูก หัก ลบล้าง ฟ้องแย้ง
                        รวมทั้งการหักใดๆ ที่กฎหมายกำหนดไว้ในเรื่องเกี่ยวกับภาษี
                        ลูกค้าจะเป็นผู้รับผิดชอบ หากได้กำหนดไว้ี ในการเสียภาษี
                        อากรใดๆ และทั้งหมด หากในการทำรายการจะต้องเสียภาษี
                      </span>
                    </h2>
                    <h2>
                      <strong>6.17.</strong>
                      <span>
                        การทำรายการทั้งหมดและการสื่อสารระหว่างลูกค้ากับบริษัทจะถูกบันทึกไว้
                        (“สิ่งบันทึก”) โดยบริษัทเพื่อประโยชน์ของลูกค้าและบริษัท
                        สิ่งบันทึกจะถูกเก็บไว้เป็นระยะเวลาหนึ่ง (1)
                        เดือนนับจากวันที่ทำรายการหรือการสื่อสาร
                        หลังจากระยะเวลาดังกล่าว สิ่งบันทึกจะถูกลบทิ้ง
                        ลูกค้าต้องแจ้งข้อโต้แย้งใดๆ
                        เกี่ยวกับการทำรายการให้บริษัททราบภายในหนึ่ง (1)
                        เดือนนับจากวันที่ทำรายการ เมื่อเกิดข้อโต้แย้งขึ้น
                        ซึ่งไม่สามารถยุติโดยผู้บริหาร ของบริษัทโดยสุจริต
                        สิ่งบันทึกจะถูกนำมาให้เป็นหลักฐานข้อโต้แย้งดังกล่าว
                        ลูกค้าและบริษัท ตกลงว่าสิ่งบันทึกถูกต้องและเป็นจริง
                        และจะมีอำนาจสูงสุดโดยจะเป็นที่พึ่งสุดท้าย
                        ในการยุติข้อโต้แย้งดังกล่าว
                        ผู้บริหารของบริษัทจะเป็นผู้ตัดสินใจคนสุดท้ายเกี่ยวกับข้อโต้แย้ง
                        และแจ้งผลการตัิดสินใจให้ลูกค้าทราบ{' '}
                      </span>
                    </h2>
                    <h1>7. ขีดจำกัดความรับผิด</h1>
                    <h2>
                      <strong>7.1.</strong>
                      <span>
                        บริษัทจะไม่รับผิดต่อลูกค้าหรือบุคคลอื่นใดหากเกิดความสูญเสียหรือความเสียหายต่อไปนี้จากสัญญา
                        การละเมิด การประมาทเลินเล่อ หรืออย่างอื่น
                        (หรือทฤษฎีกฎหมายใดๆ) แม้เมื่อในแต่ละกรณี
                        หากบริษัทได้รับแจ้งถึงความเป็นไปได้ว่าจะเกิดความสูญเสีย
                        หรือเสียหายดังกล่าวแล้วก็ตาม ไม่ว่าจะโดยตรงหรือโดยอ้อม
                        โดยไม่มีข้อจำกัด
                        ที่เห็นว่าหรืออ้างว่าได้เป็นผลมาจากหรือมีสาเหตุมาจาก
                        เว็บไซต์ บริการของบริษัท การทำรายการ
                        และ/หรือความสัมพันธ์ระหว่างลูกค้ากับบริษัท
                      </span>
                    </h2>
                    <h3>
                      <strong>7.1.1.</strong>
                      <span>
                        ความเสียหายหรือความสูญเสียโดยอ้อม พิเศษ
                        หรือที่เป็นผลสืบเนื่องตามมา
                      </span>
                    </h3>
                    <h3>
                      <strong>7.1.2.</strong>
                      <span>
                        การสูญเสียผลกำไร รายได้ สัญญา หรือเงินออมที่คาดว่าจะได้
                        หรือ
                      </span>
                    </h3>
                    <h3>
                      <strong>7.1.3.</strong>
                      <span>ความสูญเสียหรือความเสียหายที่เกิดจาก</span>
                    </h3>
                    <h4>
                      <strong>7.1.3.1.</strong>
                      <span>
                        การสูญเสีย การเสียหาย หรือการวิบัติของข้อมูลใดๆ
                      </span>
                    </h4>
                    <h4>
                      <strong>7.1.3.2.</strong>
                      <span>
                        ความล่าช้าและ/หรือการขัดจังหวะ และหรือความบกพร่องจาก
                        หรือเป็นผล สืบเนื่องจาก บริการโทรคมนาคมใดๆ
                        และ/หรืออุปกรณ์ อุปกรณ์ฮาร์ดแวร์ การกระทำของบุคคลภายนอก
                        (รวมทั้งการโจรกรรมข้อมูลและ DDOS) และ/หรือ
                      </span>
                    </h4>
                    <h4>
                      <strong>7.1.3.3.</strong>
                      <span>
                        การยกเลิกและ/หรือการเปลี่ยนแปลงรายการตามที่กำหนดไว้ในเอกสาร
                      </span>
                    </h4>
                    <h4>
                      <strong>7.1.3.4.</strong>
                      <span>การระงับหรือการปิดบัญชีตามที่กำหนดไว้ในเอกสาร</span>
                    </h4>
                    <h4>
                      <strong>7.1.3.5.</strong>
                      <span>
                        การอายัดหรือระงับเงินของลูกค้าตามที่กำหนดไว้ในเอกสาร
                      </span>
                    </h4>
                    <h4>
                      <strong>7.1.3.6.</strong>
                      <span>
                        การดำเนินการตามที่ร้องขอโดยเจ้าหน้าที่หรือสำนักงานของรัฐ
                        และ/หรือสถาบันการเงินใดๆ
                      </span>
                    </h4>
                    <h4>
                      <strong>7.1.3.7.</strong>
                      <span>
                        ข้อผิดพลาดด้านการพิมพ์ ทางเทคนิค
                        หรือข้อผิดพลาดที่ชัดเจนที่เปิดเผย
                        หรือประกาศหรือการใช้ข้อมูลการเดิมพันหรือข้อมูล
                        อื่นใดบนเว็บไซต์
                      </span>
                    </h4>
                    <h4>
                      <strong>7.1.3.8.</strong>
                      <span>
                        เหตุการณ์ที่อยู่เหนือความควบคุมของบริษัท (เหตุสุดวิสัย){' '}
                      </span>
                    </h4>
                    <h2>
                      <strong>7.2.</strong>
                      <span>
                        ลูกค้า โดยไม่จำกัดสิทธิและการเยียวยาอื่นๆ
                        ของบริษัทภายใต้ข้อกำหนดและเงื่อนไขนี้
                        เอกสารและ/หรือกฎหมายที่บังคับใช้
                        รับรองและตกลงว่าจะแก้ต่างให้บริษัทและคู่สัญญาเพิ่มเติม
                        เมื่อได้รับเอกสารร้องขอ และจะไม่เรียกร้องจากบริษัท
                        ในเรื่องค่าเสียหาย การสูญเสีย ค่าใช้จ่าย
                        รวมทั้งค่าทนายความที่สมเหตุสมผล
                        ที่บริษัทต้องจ่ายให้แก่บุคคลภายนอก หรือคู่สัญญา
                        เิพิ่มเติมใดๆ ของบริษัท
                        ที่เกิดจากการเรียกร้องค่าเสียหายโดยบุคคลภายนอก
                        ซึ่งเกี่ยวพัน โดยตรงหรือโดยอ้อมกับการฉ้อฉล การฟอกเงิน
                        หรือกิจกรรมผิดกฎหมาย การละเมิดเอกสาร
                        ที่เห็นว่าหรืออ้างว่าเป็นผลมาจากหรือมีสาเหตุมาจากการใช้
                        เว็บไซต์ บริการของบริษัท
                        และ/หรือความสัมพันธ์ระหว่างลูกค้ากับบริษัท
                      </span>
                    </h2>
                    <h2>
                      <strong>7.3.</strong>
                      <span>
                        โดยไม่จำกัดความสามารถของบริษัทในการเสาะหาวิธีเยียวยาแก้ไข
                        บริษัทอาจจำกัด ความสามารถ ของลูกค้าในการใช้เว็บไซต์
                        และ/หรือบริการของบริษัท ระงับหรือยกเลิก บัญชีลูกค้า
                        ยกเลิกเงินเดิมพัน อายัดเงิน
                        ตามวิจารณญาณเบ็ดเสร็จของบริษัท หากบริษัท
                        มีเหตุให้เชื่อหรือสงสัยได้ว่าการติดต่อของลูกค้ากับบริษัททำให้เกิด
                        กิจกรรมที่ผิดกฎหมาย
                        ลูกค้ารับทราบว่าถ้าลูกค้าใช้เว็บไซต์โดยละเมิดกฎหมายท้องถิ่นหรือกฎหมายในประเทศจากการฉ้อฉลหรือการฟอกเงิน
                        เงินที่บริษัทจ่ายให้แ่ก่ลูกค้า
                        หรือเงินที่ลูกค้าจ่ายให้แก่บริษัท
                        อาจถูกริบและ/หรือถูกระงับโดยบริษัท
                        จนถึงขอบเขตที่กฎหมายบัญญัติไว้สูงสุด บริษัท
                        จะไม่รับผิดต่อลูกค้าสำหรับการกระทำใดๆ ดังกล่าว
                        และจะไม่ก่อให้เกิดเป็นความรับผิด ต่อลูกค้ารายใด
                        ในกรณีที่จำเป็นต้องให้ข้อมูลหรือเอกสารเกี่ยวกับลูกค้าไปยังเจ้าหน้าที่
                        ที่เกี่ยวข้องในกรณีดังกล่าว
                      </span>
                    </h2>
                    <h1>8. การใช้เว็บไซต์และวิดีโอสตรีมมิ่ง</h1>
                    <h2>
                      <strong>8.1.</strong>
                      <span>
                        การใช้เว็บไซต์โดยทั่วไปอยู่ภายใต้ข้อกำหนดการใช้งานและนโยบายความเป็นส่วนตัว
                        ซึ่งเพิ่มจากข้อกำหนดและเงื่อนไขนี้
                      </span>
                    </h2>
                    <h2>
                      <strong>8.2.</strong>
                      <span>
                        บริษัทขอสงวนสิทธิ์ที่จะถอนเว็บไซต์ หรือองค์ประกอบใดๆ
                        ของเว็บไซต์ออก ได้ตลอดเวลา
                        และจะไม่รับผิดต่อลูกค้าไม่ว่าในทางใดซึ่งเป็นผลจากการกระทำดังกล่าว
                      </span>
                    </h2>
                    <h2>
                      <strong>8.3.</strong>
                      <span>
                        การเสนอภาพวิดีโอสตรีมมิ่งเหตุการณ์ทางอินเทอร์เน็ตโดยบริษัทเป็นทรัพย์สินภายใต้กรรมสิทธิ์และลิขสิทธิ์ของเจ้าของทรัพย์สินทางปัญญาโดยชอบธรรม
                        (ซึ่งสงวนลิขสิทธิ์ตามกฎหมาย) ซึ่งบริษัทได้รับอนุญาตแล้ว
                        ลูกค้าสามารถเข้าถึงภาพวิดีโอสตรีมมิ่งของเหตุการณ์ทางอินเทอร์เน็ตตามกฎกติกาที่เจาะจง
                        ข้อกำหนดและเงื่อนไขที่อาจบังคับใช้สำหรับข้อเสนอบางอย่างตามที่เผยแพร่แยกกันโดยบริษัท
                        เป็นครั้งคราว
                        และลูกค้ายินยอมที่จะปฏิบัติตามด้วยความเคร่งครัด
                        ไม่ว่าเวลาใดก็ตาม ห้ามไม่ให้ลูกค้าทำสำเนา แจกจ่ายซ้ำ
                        หรือใช้ภาพวิดีโอที่เสนอให้โดยไม่ได้รับอนุญาต
                        ซึ่งจะถือเป็นการละเมิดข้อกำหนดและเงื่อนไขนี้
                        และอนุญาตให้บริษัททำการปิดบัญชีลูกค้า โดยทันที
                      </span>
                    </h2>
                    <h1>9. โปรโมชั่นและข้อเสนอพิเศษ</h1>
                    <h2>
                      <strong>9.1.</strong>
                      <span>
                        บริษัทจะจัดโปรโมชั่น ข้อเสนอพิเศษ หรือสิ่งจูงใจอื่นๆ
                        เป็นครั้งคราวสำหรับลูกค้า
                        สิ่งเหล่านี้ไม่มีค่าเป็นเงินสดและอยู่ภายใต้ข้อกำหนดที่ระบุไว้ในโปรโมชั่น
                        ข้อเสนอพิเศษ หรือสิ่งจูงใจนั้นๆ
                        ซึ่งลูกค้าต้องยึดถือและปฏิบัติตาม
                        บริษัทขอสงวนสิทธิ์โดยไม่จำเป็นต้องแจ้ง
                        ให้ลูกค้าทราบล่วงหน้า ในการถอนหรือเปลี่ยนแปลงโปรโมชั่น
                        ข้อเสนอพิเศษ หรือสิ่งจูงใจ ในเวลาใดก็ได้
                        ตามวิจารณญาณของบริษัทแต่เพียงผู้เดียว
                      </span>
                    </h2>
                    <h1>10. กฎกติกาทั่วไปและกฎกติกาสำหรับเหตุการณ์ใดๆ</h1>
                    <h2>
                      <strong>10.1.</strong>
                      <span>
                        บริการของบริษัท การทำรายการทั้งหมด การเดิมพัน
                        ข้อมูลเหตุการณ์และการเดิมพัน
                        อยู่ภายใต้ข้อกำหนดและเงื่อนไขนี้ เอกสารและ
                        กฎกติกาและระเบียบข้อบังคับการเดิมพัน
                        ซึ่งลูกค้าได้ยอมรับว่าได้อ่านและตกลงยินยอมที่จะปฏิบัติตาม
                        นอกเหนือจากข้อกำหนด และเงื่อนไขนี้ เอกสาร
                        โดยเฉพาะอย่างยิ่ง กฎกติกาและระเบียบ ข้อบังคับการเดิมพัน
                        จะระบุกฎกติกาการเดิมพันที่บังคับใช้กับบริการของบริษัททั้งหมด
                        การทำรายการ การเดิมพัน เหตุการณ์ และข้อมูลการเดิมพัน
                        ขณะที่เหตุการณ์ที่เจาะจงและกฎกติกาและ ระเีบียบข้อบังคับ
                        การเดิมพัน
                        จะระบุกฎกติกาการเดิมพันที่เจาะจงสำหรับเหตุการณ์หรือประเภทการเดิมพัน
                        อย่างใดอย่างหนึ่งโดยเฉพาะ
                      </span>
                    </h2>
                    <h2>
                      <strong>10.2.</strong>
                      <span>
                        ถ้ามีความขัดแย้งกัน ความแตกต่างกัน
                        หรือความไม่ตรงกันระหว่างข้อกำหนดและเงื่อนไข กับ
                        กฎกติกาและระเบียบข้อบังคับการเดิมพัน
                        ให้นำอย่างหลังมาพิจารณา ถ้ากฎกติกาและ
                        ระเบียบข้อบังคับการเดิมพันไม่ได้กล่าวถึงเรื่องใดเรื่องหนึ่งโดยเฉพาะ
                        บทบัญญัติที่สอดคล้อง
                        กันของหน่วยงานกำกับดูแลเหตุการณ์จะถูกนำมาบังคับใช้โดยการเทียบเคียงกันหรือ
                        การตีความ (หากสามารถทำได้)
                      </span>
                    </h2>
                    <h2>
                      <strong>10.3.</strong>
                      <span>
                        สำหรับการเดิมพันการเงิน
                        โปรดดูข้อกำหนดและเงื่อนไขการเดิมพันการเงินที่อยู่ในกฎกติกาและระเบียบข้อบังคับ
                        การเดิมพัน
                      </span>
                    </h2>
                    <h1>11. ทั่วไป</h1>
                    <h2>
                      <strong>11.1.</strong>
                      <span>
                        เว็บไซต์ไม่ใช่ และไม่ควรถือว่าเว็บไซต์ เป็นข้อเสนอ
                        การเชิญชวนหรือการเรียกร้อง
                        จากบริษัทให้เข้ามาใช้หรือสมัครเป็นสมาชิกบริการการพนันในเขตอำนาจศาลใดๆ
                        ที่กิจกรรมดังกล่าวไม่ถูกต้องตามกฎหมาย{' '}
                      </span>
                    </h2>
                    <h2>
                      <strong>11.2.</strong>
                      <span>
                        ประกาศหรือการจัดเตรียมอื่นใด
                        ไม่ว่าจะเป็นลายลักษณ์อักษรหรือทางวาจา โดยชัดแจ้งหรือ
                        เป็นนัย (รวมถึงการใช้หรือจารีตประเพณีและข้อกำหนด
                        ที่เกิดขึ้นระหว่างการติดต่อ)
                        ระหว่างลูกค้ากับบริษัทอันเกี่ยวเนื่องกับสาระสำคัญในที่นี้
                        จะถือว่าได้ถูกแทนที่ ยกเลิก ไม่เป็นผล และเป็นโมฆะ
                      </span>
                    </h2>
                    <h2>
                      <strong>11.3.</strong>
                      <span>
                        ไม่มีข้อความใดในเอกสารนี้ที่สามารถตีความหรืออนุมานว่าเป็นการสร้างหรือสถาปนาความสัมพันธ์ประเภทอื่นใดระหว่างบริษัทกับลูกค้า
                      </span>
                    </h2>
                    <h2>
                      <strong>11.4.</strong>
                      <span>
                        การที่บริษัทไม่สามารถหรือมีความล่าช้าในการใช้สิทธิหรือเยียวยาแก้ไขภายใต้เอกสารนี้
                        ไม่ถือว่าเป็น
                        หรืออนุมานว่าเป็นการละเว้นสิทธิหรือการเยียวยาแก้ไขดังกล่าว
                        และการใช้ สิทธิใดๆ
                        หรือการเยียวยาแก้ไขเพียงอย่างเดียวหรือเพียงบางส่วนจะไม่ทำให้สิ้นโอกาส
                        ในการใช้สิทธิหรือการเยียวยาแก้ไขนั้นต่อไป{' '}
                      </span>
                    </h2>
                    <h2>
                      <strong>11.5.</strong>
                      <span>
                        บทบัญญัติแต่ละข้อที่ระบุในเอกสารนี้ให้ตีความว่าเป็นอิสระต่อกันกับบทบัญญัติื่อื่นๆ
                        ทุกข้อ
                        เพื่อที่ว่าหากบทบัญญัติใดของเอกสารได้รับการวินิจฉัยโดยศาลหรือพนักงานเจ้าหน้าที่ว่าผิดกฎหมาย
                        ไม่มีผล และ/หรือบังคับใช้ไม่ได้ การวินิจฉัยดังกล่าว
                        จะได้ไม่มีผล ต่อบทบัญญัติอื่นใดในเอกสารนี้
                        และบทบัญญัติอื่นใดยังคงมีผลบังคับใช้อย่างเต็มที่ต่อไป
                      </span>
                    </h2>
                    <h2>
                      <strong>11.6.</strong>
                      <span>
                        ลูกค้ารับทราบและรับรองว่าได้เสาะหาและได้รับบริการที่ปรึกษาทางกฎหมายที่เป็นอิสระด้วยตนเองในเรื่องเกี่ยวกับสิทธิ
                        ข้อผูกมัด ข้อกำหนด เงื่อนไข และผลสืบเนื่องตาม
                        มาอันเกิดจากเอกสาร
                        ซึ่งทุกอย่างได้รับคำอธิบายและได้เข้าใจอย่างครบถ้วนแล้ว
                      </span>
                    </h2>
                    <h2>
                      <strong>11.7.</strong>
                      <span>
                        ข้อกำหนดและเงื่อนไขนี้จะบังคับใช้และตีความตามกฎหมายของประเทศฟิลิปปินส์
                      </span>
                    </h2>
                    <h2>
                      <strong>11.8.</strong>
                      <span>
                        ข้อโต้แย้งใดๆ ข้อโต้เถียง
                        หรือการเรียกร้องค่าเสียหายอันเกิดจากหรือเกี่ยวเนื่องกับข้อตกลงนี้
                        ให้ส่งไปที่และตัดสินโดยศาลชำนัญพิเศษ
                        ที่ประกอบด้วยอนุญาโตตุลาการหนึ่งคนที่ได้รับ
                        แต่งตั้งโดยองค์การออกใบอนุญาต
                        บัลลังก์ว่าความของอนุญาโตตุลาการ จะเป็นที่ ซานตาแอนนา
                        คากายัน ประเทศฟิลิปปินส์
                        สถานที่ซึ่งกำหนดโดยองค์การออกใบอนุญาต
                        ภาษาที่ใช้สำหรับการอนุญาโตตุลาการคือภาษาอังกฤษ
                        และให้ระเบียบข้อบังคับที่จัดทำขึ้น
                        โดยองค์การออกใบอนุญาตควบคุมกระบวนการนี้
                        ศาลอนุญาโตตุลาการจะมีสิทธิออกคำสั่ง
                        และคำตัดสินของศาลจะเป็นคำสั่งบังคับ
                        และถือเป็นที่สุดและบังคับใช้อย่างเคร่งครัด
                        กับคู่สัญญาซึ่งสละสิทธิในเขตอำนาจศาลอื่นใดหรือสถานที่อื่นใดที่อาจนำมาใช้และตกลงที่จะปฏิบัติตามคำตัดสินของอนุญาโตตุลาการโดยละเว้นสิทธิในการอุธรณ์ไม่ว่าประเภทใด
                      </span>
                    </h2>
                    <h2>
                      <strong>11.9.</strong>
                      <span>
                        การแจ้งไปยังลูกค้าหรือบริษัทที่เกี่ยวกับเอกสาร
                        ต้องกระทำเป็นลายลักษณ์อักษร และต้อง ส่งมอบด้วยตนเอง
                        หรือส่งโดยไปรษณีย์ลงทะเบียนพิเศษ ไปรษณีย์อากาศแบบชำระ
                        ค่าอากรไปรษณีย์แล้ว จดหมายอิเล็กทรอนิกส์
                        หรือโทรสารไปยังที่อยู่หรือหมายเลข
                        โทรสารที่ระบุไว้ด้านล่าง
                      </span>
                    </h2>
                    <h3>
                      <strong>11.9.1.</strong>
                      <span>
                        ส่งไปยังลูกค้า:
                        ตามข้อมูลที่ระบุไว้ในข้อมูลประจำตัวลูกค้า
                        หากการส่งประกาศแจ้งด้วยวิธีใดๆ
                        ที่ระบุไว้ในข้อมูลประจำตัวลูกค้าล้มเหลวสามครั้งติดต่อกัน
                        โดยเว้นระยะห่างหนึ่งชั่วโมง
                        จะถือว่าได้ส่งประกาศแจ้งดังกล่าวแล้วหลังการพยายามครั้งที่สาม{' '}
                      </span>
                    </h3>
                    <div id='1-3' class='page_top'>
                      <a href='#top' class='button2'>
                        <span>ด้านบนสุด</span>
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Card>



        <div
          style={{
            background: 'white',
            width: '100%',
            textAlign: 'center',
            position: 'fixed',
            bottom: 0,
            height: '100px',
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className='center' style={{ width: '100%' }}>
            <Form layout='vertical' style={{ width: '100%' }} onFinish={onAgree}>
              <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', width: '100%' }}>
                <Form.Item style={{ width: '80%' }}>
                  <div className='center'>
                    <Button
                      htmlType='submit'
                      className='loginButton'
                      style={{
                        width: '80%',
                        background: 'linear-gradient(rgb(235, 220, 166), rgb(138, 111, 54) 59%, rgb(188, 159, 91))',
                        height: '40px',
                        color: '#fff',
                        borderRadius: '999px'
                      }}
                    >
                      ยอมรับ
                    </Button>
                  </div>
                </Form.Item>
                <Form.Item style={{ width: '80%' }}>
                  <div className='center' style={{ width: '100%' }}>
                    <Button
                      htmlType='submit'
                      className='loginButton'
                      onClick={onLogout}
                      style={{
                        width: '80%',
                        height: '40px',
                        borderRadius: '999px',
                        color: '#BFBFBF',
                        borderColor: '#BFBFBF'
                      }}
                    >
                      ไม่ยอมรับ
                    </Button>
                  </div>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </body>
    </div>
  );
}

export default AgreementPage;
