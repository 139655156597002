import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Table,
  Card,
  Button,
  Row,
  Col,
  message,
  Popconfirm,
  Checkbox,
  Menu,
  Collapse,
  Modal,
} from 'antd';
import hide from '../../assets/images/hide.png';
import show from '../../assets/images/show.png';
import ball from '../../assets/images/ball.png';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import './index.css';
import api from '../../api';
import { useDataState, useTable } from '../../hooks';
import moment from 'moment';
import { useForm } from 'antd/es/form/Form';
import { getShowHandicapScore } from '../../constants/handicap';
import StepBillCard from '../../components/StepBillCard/StepBillCard';
import HandicapBillCard from '../../components/StepBillCard/HandicapBillCard';
const { Panel } = Collapse;

function StepPage() {
  const history = useHistory();
  const username = localStorage.getItem('username');
  const [selectedMatches, setSelectedMatches] = useState([]);
  const [loading, setLoading] = useState();
  const [showStep, setShowStep] = useState();
  const [value, setValue] = useState();
  const [form] = useForm();
  const [confirmModalOpen, setConfirmModalOpen] = useState();
  const [showMenu, setShowMenu] = useState(true);

  // const { table }
  const { Column, ColumnGroup } = Table;
  const { tableProps } = useTable(() => {
    return api.user.getStepList({
      date: moment(),
    });
  }, [loading]);

  const userData = useDataState();
  const billData = useDataState();
  const stepBillData = useDataState();

  useEffect(() => {
    const promise = api.user.getUserDetail(username);
    userData.load(Promise.resolve(promise).then((value) => value));
  }, [loading]);

  useEffect(() => {
    const promise = api.user.getHandicapBill({ username });
    billData.load(Promise.resolve(promise).then((value) => value));
  }, [loading]);

  useEffect(() => {
    const promise = api.user.getStepBill({ username });
    stepBillData.load(Promise.resolve(promise).then((value) => value));
  }, [loading]);

  const bill = billData?.data?.data;
  const stepBill = stepBillData?.data?.data;
  const user = userData?.data?.data;
  const maxPaidValue = user?.step_max_paid

  const tabledata = tableProps?.dataSource?.data;

  function getItem(label, key, icon, path, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
      path,
    };
  }
  function getPath(key) {
    const list = ['/handicap', '/step'];
    return list[key];
  }

  const onSelectMenu = (index) => {
    history.push(getPath(index));
  };

  const items = [
    getItem('แสดงเมนูกีฬา', 'sub1', '', '', [
      getItem(
        'ฟุตบอล',
        'g1',
        '',
        '',
        [
          getItem('ราคาต่อรอง', '0', '', '/handicap'),
          getItem('มิกซ์ พาร์เลย์', '1', '', '/step'),
        ],
        'group'
      ),
    ]),
  ];

  const onCancel = () => {
    closeConfirmModal();
    setSelectedMatches([]);
    setValue();
    form.setFieldValue('bet_credits', '');
  };

  const onSelectMatch = (data) => {
    const temp = [...selectedMatches];
    const index = temp.findIndex((item) => item._id === data._id);
    if (index === -1) {
      temp.push(data);
    } else {
      temp[index] = data;
    }
    setSelectedMatches(temp);
  };

  const unSelectMatch = (data) => {
    var temp = [...selectedMatches];
    temp = temp.filter(function (match) {
      return match._id !== data._id;
    });
    setSelectedMatches(temp);
  };

  const onBet = async () => {
    setLoading(true);
    // let text = 'เดิมพันยืนยัน?';
    // if (window.confirm(text) == true) {
    let matchesOpen = true;
    const payload = {
      selectedMatches: [...selectedMatches],
      username: username,
      bet_credits: value,
    };

    if (user?.isSpecial != true) {
      await selectedMatches.forEach(async (match) => {
        if (match.is_open != true) {
          matchesOpen = false;
        }
      });
    }

    if (matchesOpen === false) {
      message.error('มีคู่บอลบางคู่มีผู้เดิมพันจำนวนมาก กรุณาลองใหม่อีกครั้ง');
    } else {
      await api.user
        .betStep(payload)
        .then((res) => {
          message.success('ทำรายการสำเร็จ');
          onCancel();
          window.location.reload();
        })
        .catch((err) => {
          if (err.response.status === 501) {
            message.error(`เดิมพัน ${user.bill_per_day} บิล/วัน`);
          } else if (err.response.status === 502) {
            message.error(
              'มีคู่บอลบางคู่มีผู้เดิมพันจำนวนมาก กรุณาลองใหม่อีกครั้ง'
            );
          } else if (err.response.status === 400) {
            message.error('เครดิตไม่เพียงพอ');
          } else message.error('เกิดข้อผิดพลาด');
        });
    }

    setLoading(false);
    // } else {
    // }
  };

  const showConfirmModal = () => {
    setConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const multiplier =
    selectedMatches?.length > 0 ? Math.pow(2, selectedMatches.length) - 1 : 0;

  const selectedMatchesContent = selectedMatches?.map((value) => {
    return (
      <div
        style={{
          color: value?.selectedTeam === value?.team_a ? '#B50000' : 'black',
        }}
      >
        {`ทีมที่เลือก : ${value?.selectedTeam || ''}`}
      </div>
    );
  });

  const selectedMatchesCards = selectedMatches?.map((value) => {
    const isSelectedTeamHandicap = value?.selectedTeam === value?.handicap_team;
    return (
      <Card
        style={{
          position: 'relative',
          borderRadius: '0px',
          background: '#BECDEA',
          margin: '0px',
        }}
      >
        <div
          style={{
            fontWeight: 'bold',
            color: '#000099',
          }}
        >
          ฟุตบอล / แต้มต่อ
        </div>
        <Button
          type='text'
          icon={<CloseOutlined onClick={() => unSelectMatch(value)} />}
          style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
        />
        <div
          style={{
            color: isSelectedTeamHandicap ? '#B50000' : 'black',
            fontWeight: 'bold',
          }}
        >
          {`${value?.selectedTeam}`}
        </div>
        <div
          style={{
            fontWeight: 'bold',
            color: ' #606060',
          }}
        >
          {`${isSelectedTeamHandicap ? '-' : ''}${value?.handicap_score}`}
        </div>
        <div style={{ fontSize: '11px' }}>
          <div>{value?.team_a}</div>
          <div>{value?.team_b}</div>
        </div>
      </Card>
    );
  });

  const confirmModal = (
    <Modal
      open={confirmModalOpen}
      onClose={closeConfirmModal}
      onCancel={closeConfirmModal}
      style={{ fontFamily: 'Kanit' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>เดิมพันยืนยัน?</div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={onBet}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeConfirmModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={loading}
              style={{ background: '#F7D249', color: 'black' }}
            >
              ยืนยัน
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  return (
    <div className='container'>
      {confirmModal}
      <Row style={{ height: '100%' }}>
        <Col span={5} style={{ padding: '10px', background: '#B5B5B5' }}>
          {selectedMatches?.length === 0 && (
            <div>
              <table>
                <tr>
                  {showMenu ?
                    <div className='PanelShowMenuBg' onClick={() => setShowMenu(false)}>
                      <img width={20} src={hide} />
                      <div>
                        ซ่อนเมนูกีฬา
                      </div>
                    </div> :
                    <div className='PanelShowMenuBg' onClick={() => setShowMenu(true)}>
                      <img width={16} src={show} />
                      <div>แสดงเมนูกีฬา</div>
                    </div>}
                </tr>
                {showMenu && <>
                  <tr>
                    <div className='menu'>
                      <img width={20} src={ball} />
                      ฟุตบอล
                    </div>
                    <a className='menu-list' onClick={() => onSelectMenu(0)}>
                      ราคาต่อรอง
                    </a>
                    <a className='menu-list' onClick={() => onSelectMenu(1)}>
                      มิกซ์ พาร์เลย์
                    </a>
                  </tr>
                </>}
              </table>
              <table>
                <tr>
                  <div className='PanelShowMenuBg' style={{ marginTop: '5px' }} onClick={() => setShowMenu(true)}>
                    <img width={16} src={show} />
                    <div>รอ และ ยกเลิก</div>
                  </div>
                </tr>
                <tr>
                  <div style={{ backgroundColor: '#e9f3fd', paddingBottom: '5px' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '10px',
                        paddingTop: '10px'
                      }}
                    >
                      <Button
                        style={{
                          width: '45%',
                          height: '25px',
                          fontSize: '11px',
                          background:
                            'linear-gradient(to bottom, #FAFAFA, #DEDEDE)',
                        }}
                      >
                        ที่รอ
                      </Button>
                      <Button
                        style={{
                          width: '45%',
                          height: '25px',
                          fontSize: '11px',
                          background:
                            'linear-gradient(to bottom, #FAFAFA, #DEDEDE)',
                        }}
                      >
                        โมฆะ
                      </Button>
                    </div>
                    {bill && bill.map((item) => {
                      return <HandicapBillCard bill={item} />
                    })}
                    {stepBill && stepBill.map((item) => {
                      return <StepBillCard stepBill={item} />
                    })}
                  </div>
                </tr>
              </table>
            </div>
          )}
          {selectedMatches?.length > 0 && (
            <Card style={{ background: '#e0e4f0', borderRadius: '0px' }}>
              <div style={{ padding: '5px', background: 'white' }}>
                {selectedMatchesCards}
              </div>
              <Form
                initialValues={{
                  remember: true,
                }}
                onFinish={showConfirmModal}
                style={{ fontFamily: 'Kanit' }}
                form={form}
              >
                <Form.Item
                  label='จำนวนเงิน : '
                  name='bet_credits'
                  rules={[
                    { required: true, message: 'กรุณากรอกจำนวนเงิน' },
                    {
                      validator: (rule, value) => {
                        if (user?.isSpecial) {
                          return Promise.resolve();
                        }
                        if (value < 10) {
                          return Promise.reject('เดิมพันขั้นต่ำ 10 บาท');
                        }
                        if (value * multiplier > maxPaidValue) {
                          return Promise.reject(
                            `การจ่ายเงินขั้นสูงสุด ${maxPaidValue} บาท`
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                    {
                      validator: (rule, value) => {
                        if (selectedMatches.length > 10) {
                          return Promise.reject('เดิมพันสูงสุด 10 คู่');
                        }
                        if (selectedMatches.length < 3) {
                          return Promise.reject('เดิมพันขั้นต่ำ 3 คู่');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    type='number'
                    onChange={(e) => setValue(e.target.value)}
                  ></Input>
                </Form.Item>
                <Row>
                  <Col span={12} style={{ padding: '1px' }}>
                    <div style={{ fontSize: '10px', background: '#C9C9D3' }}>
                      การจ่ายเงินขั้นสูงสุด :{' '}
                    </div>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      fontSize: '10px',
                      padding: '1px',
                      textAlign: 'right',
                    }}
                  >
                    <div style={{ background: 'white' }}>
                      {value * multiplier > maxPaidValue
                        ? maxPaidValue
                        : value * multiplier || 0}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ padding: '1px' }}>
                    <div style={{ fontSize: '10px', background: '#C9C9D3' }}>
                      เดิมพันขั้นต่ำสุด :{' '}
                    </div>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      color: '#003399',
                      fontSize: '10px',
                      padding: '1px',
                      textAlign: 'right',
                    }}
                  >
                    <div style={{ background: 'white' }}>10</div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ padding: '1px' }}>
                    <div style={{ fontSize: '10px', background: '#C9C9D3' }}>
                      การจ่ายเงินขั้นสูงสุด :
                    </div>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      color: '#B50000',
                      fontSize: '10px',
                      padding: '1px',
                      textAlign: 'right',
                    }}
                  >
                    <div style={{ background: 'white' }}>{maxPaidValue}</div>
                  </Col>
                </Row>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '10px',
                    height: '40px'
                  }}
                >
                  <Form.Item>
                    <Button
                      type='primary'
                      htmlType='submit'
                      style={{
                        color: 'white', background: 'linear-gradient(to bottom, #87a5d9, #3a6bba)',
                        width: '100%'
                      }}
                    >
                      ทำรายการ
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button onClick={onCancel} style={{ width: '100%' }}>ยกเลิก</Button>
                  </Form.Item>
                </div>
              </Form>
            </Card>
          )}
        </Col>
        <Col
          span={19}
          style={{ padding: '10px', background: '#F3F3F5' }}
        >
          <table style={{ border: '1px solid black', fontSize: '11px', margin: '0px', width: '100%', tableLayout: 'auto' }}>
            <thead style={{ backgroundColor: '#4B3925', color: '#fff' }}>
              <tr>
                <th style={{ width: '15%' }} rowSpan={2}>เวลา</th>
                <th style={{ width: '40%' }} rowSpan={2}>คู่แข่งขัน</th>
                <th style={{ width: '45%' }} colspan="4">เต็มเวลา</th>
              </tr>
              <tr>
                <th style={{ width: '80px' }}>ราคาต่อรอง</th>
                <th style={{ width: '80px' }}>ทีมเจ้าบ้าน</th>
                <th style={{ width: '80px' }}>ทีมเยือน</th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: '#E8E8E8' }}>
              {tabledata?.length > 0 && tabledata.map((row) => {
                return <tr>
                  <td style={{ width: '15%' }}>
                    <p class="bold">{moment(row.match_start_at).format('DD/MM')}</p>
                    <p class="bold">{moment(row.match_start_at).format('HH:mm')}</p>
                  </td>
                  <td style={{ width: '40%', height: '40px', verticalAlign: 'top' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', marginRight: '5px' }}>
                      <p className="bold" style={{ margin: 0, padding: 0, color: row.team_a === row.handicap_team ? '#B50000' : '', }}>{row.team_a}</p>
                      <p className="bold" style={{ margin: 0, padding: 0, color: row.team_b === row.handicap_team ? '#B50000' : '', }}>{row.team_b}</p>
                    </div>
                  </td>
                  <td style={{ width: '15%', height: '40px', verticalAlign: 'top' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-start', marginRight: '5px' }}>
                      <p className="bold" style={{ margin: 0, padding: 0 }}>{getShowHandicapScore(row.handicap_score)}</p>
                    </div>
                  </td>
                  <td style={{ width: '15%', height: '40px', verticalAlign: 'top' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-start', marginRight: '5px' }}>
                      <a
                        onClick={() => {
                          onSelectMatch({ ...row, selectedTeam: row.team_a });
                        }}
                        className="link" style={{ margin: 0, padding: 0 }}>2.00</a>
                    </div>
                  </td>
                  <td style={{ width: '15%', height: '40px', verticalAlign: 'top' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-start', marginRight: '5px' }}>
                      <a
                        onClick={() => {
                          onSelectMatch({ ...row, selectedTeam: row.team_b });
                        }}
                        className="link" style={{ margin: 0, padding: 0 }}>2.00</a>
                    </div>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  );
}

export default StepPage;
