import { Card } from "antd";
import { useState } from "react";
import { DownOutlined } from '@ant-design/icons';
import moment from "moment";

function StepBillCard(props) {
    const { stepBill } = props
    const [showStep, setShowStep] = useState(false);
    return <Card
        style={{
            border: '1px solid #8c8c8c',
            borderRadius: '0px',
            marginTop: '10px',
            background: '#e4e4e4',
            fontSize: '10px',
            marginLeft: '5px',
            marginRight: '5px',
        }}
        className='padding'
    >
        <div>{`#${stepBill?.bill_number} (${moment(
            stepBill?.bet_at
        ).format('DD/MM HH:mm')})`}</div>
        <div
            style={{
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <div>มิกซ์ พาร์เลย์</div>
            <DownOutlined onClick={() => setShowStep(!showStep)} />
        </div>
        {showStep &&
            stepBill?.selected_matches.map((match) => {
                return (
                    <div
                        style={{
                            border: '1px solid #c0c0c0',
                            marginLeft: '5px',
                            marginBottom: '5px',
                        }}
                    >
                        <div>{`${match.match_id.team_a} -vs- ${match.match_id.team_b}`}</div>
                        <div>
                            {moment(match.match_id.match_start_at).format(
                                'HH:mm'
                            )}
                        </div>
                        <div
                            style={{
                                fontWeight: 'bold',
                                color:
                                    match.handicap_team === match.selected_team
                                        ? '#B50000'
                                        : '',
                            }}
                        >
                            {match.selected_team}
                            <span
                                style={{ color: 'black', marginLeft: '10px' }}
                            >
                                {' '}
                                {`(${match.handicap_team === match.selected_team
                                    ? '-'
                                    : ''
                                    }${match.handicap_score}) 2.0`}
                            </span>
                        </div>
                        <div>แต้มต่อ</div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                style={{
                                    background: 'green',
                                    color: 'white',
                                }}
                            >
                                เดิมพันสำเร็จ
                            </div>
                            <div></div>
                        </div>
                    </div>
                );
            })}
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <div style={{ background: 'green', color: 'white' }}>
                เดิมพันสำเร็จ
            </div>
            <div style={{ fontWeight: 'bold' }}>
                {stepBill?.bet_credits}
            </div>
        </div>
    </Card>
}

export default StepBillCard;