import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { useHistory } from 'react-router';
import flagTH from '../../assets/images/icn-flag-th.png';
import arrowDown from '../../assets/images/arrow-down.png';
import logo from '../../assets/images/logo.png';
import './index.css';
import api from '../../api';
import { userErrorHandler } from '../../api/user';

function LoginPage() {
  useEffect(() => { }, []);

  const history = useHistory();

  const onLogin = async (data) => {
    await api.user
      .login(data)
      .then((resp) => {
        if (resp.data) {
          localStorage.setItem('token', resp.data.token);
          localStorage.setItem('username', resp.data.username);
          history.push('/agreement');
          window.location.reload();
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          message.error('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง');
        } else {
          message.error('เกิดข้อผิดพลาด');
        }
        userErrorHandler(err);

      });
  };

  return (
    <div className='container-login'>
      <body>
        <div
          style={{
            top: '20%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <img style={{ width: '180px' }} src={logo}></img>
        </div>
      </body>
      <div className='login-container'>
        <div style={{ width: '100%' }}>
          <div className='center'>
            <div style={{ color: '#ba995d' }}>เข้าสู่ระบบ</div>
          </div>
          <hr className='divider' />
          <div className='center'>
            <Form layout='vertical' style={{ width: '80%' }} onFinish={onLogin}>
              <div style={{ color: '#ba995d' }}>ชื่อผู้ใช้</div>
              <Form.Item
                name='username'
                style={{ width: '100%' }}
                rules={[
                  { required: true, message: 'Please input your username!' },
                ]}
              >
                <Input
                  style={{ width: '100%', borderRadius: '999px', height: '40px' }}
                  placeholder='ชื่อผู้ใช้'
                ></Input>
              </Form.Item>
              <div style={{ color: '#ba995d' }}>รหัสผ่าน</div>
              <Form.Item
                type='password'
                name='password'
                style={{ width: '100%', marginBottom: '10px' }}
                rules={[
                  { required: true, message: 'Please input your password!' },
                ]}
              >
                <Input
                  type='password'
                  style={{ width: '100%', borderRadius: '999px', height: '40px' }}
                  placeholder='รหัสผ่าน'
                ></Input>
              </Form.Item>
              <div className='center' style={{ gap: 10 }}>
                <img src={flagTH} height={'20px'} width={'20px'} />
                <div style={{ color: '#fff', fontSize: '10px' }}>ภาษาไทย</div>
                <img src={arrowDown} height={'10px'} width={'10px'} />
              </div>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  htmlType='submit'
                  style={{
                    marginTop: '10px',
                    background: 'linear-gradient(to bottom, #e7d39d, #b19560)',
                    fontSize: '14px',
                    border: '0px',
                    borderRadius: '999px',
                    width: '100%',
                    height: '40px'
                  }}
                >
                  เข้าสู่ระบบ
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div >
  );
}

export default LoginPage;
