import { Row } from 'antd';
import React from 'react';
import sp1 from '../../assets/images/games/sp1.png';
import sp2 from '../../assets/images/games/sp2.png';
import sp3 from '../../assets/images/games/sp3.png';
import sp4 from '../../assets/images/games/sp4.png';
import sp5 from '../../assets/images/games/sp5.png';
import sp6 from '../../assets/images/games/sp6.png';
import sp7 from '../../assets/images/games/sp7.png';
import sp8 from '../../assets/images/games/sp8.png';
import sp9 from '../../assets/images/games/sp9.png';
import GameButton from './GameButton';

function SportContentList(props) {
  const { onLogin } = props
  return (
    <div className='roll-out'>
      <Row gutter={[13, 8]} style={{ marginLeft: '5px', marginTop: '10px' }}>
        <GameButton icon={sp1} onClick={onLogin} />
        <GameButton icon={sp2} />
        <GameButton icon={sp3} />
        <GameButton icon={sp4} />
        <GameButton icon={sp5} />
        <GameButton icon={sp6} />
        <GameButton icon={sp7} />
        <GameButton icon={sp8} />
        <GameButton icon={sp9} />
      </Row>
    </div>
  );
}

export default SportContentList;
