import React, { useState, useEffect } from 'react';
import {
  Popover,
  Menu,
  Form,
  Input,
  Table,
  Card,
  Button,
  Row,
  Col,
  message,
  Collapse,
  Modal,
  Divider,
  Tag,
  Spin,
} from 'antd';
import { useHistory } from 'react-router';
import './index.css';
import api from '../../api';
import { useDataState, useTable } from '../../hooks';
import moment from 'moment';
import { useForm } from 'antd/es/form/Form';
import { getShowHandicapScore } from '../../constants/handicap';
import { userErrorHandler } from '../../api/user';

function BillPage() {
  const history = useHistory();
  const username = localStorage.getItem('username');
  const [loading, setLoading] = useState();
  const [detailLoading, setDetailLoading] = useState(false);
  const [selectedBill, setSelectedBill] = useState();
  const [detailModalOpen, setDetailModalOpen] = useState();
  const billData = useDataState();

  // const { table }
  const { tableProps } = useTable(() => {
    return api.user.getBillList({
      username,
    }).catch((err) => {
      userErrorHandler(err);
    });
  }, [loading]);

  useEffect(() => {
    const id = selectedBill?._id;
    const promise = api.user.getBillDetail(id)
      .catch((err) => {
        userErrorHandler(err);
      });;
    billData.load(Promise.resolve(promise).then((value) => value));
  }, [selectedBill]);

  const tabledata = tableProps?.dataSource?.data;
  const bill = billData?.data?.data;

  const showDetail = (value) => {
    setSelectedBill(value);
    setDetailModalOpen(true);
  };

  const closeDetail = () => {
    setDetailModalOpen(false);
  };

  const pageContent = tabledata?.map((value) => {
    const statusColor =
      value.status === 'ชนะ'
        ? '#06C755'
        : value.status === 'แพ้'
          ? '#FF3232'
          : value.status === 'เสมอ'
            ? ''
            : '';
    const statusText =
      value.status === 'ชนะ'
        ? '+'
        : value.status === 'แพ้'
          ? ''
          : value.status === 'เสมอ'
            ? ''
            : '';

    return (
      <Card
        style={{ margin: '20px', fontFamily: 'Kanit' }}
        onClick={() => showDetail(value)}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: 'bold',
          }}
        >
          <div>{moment(value.bet_at).format('DD/MM/YYYY HH:mm')}</div>
          <div style={{ color: statusColor }}>{`${statusText} ${value.reward
            ? value.reward
            : value.status === 'รอผลรางวัล'
              ? 'รอผลรางวัล'
              : 0
            }`}</div>
        </div>
      </Card>
    );
  });

  const detailModal = (
    <Modal
      open={detailModalOpen}
      onClose={closeDetail}
      onCancel={closeDetail}
      style={{ fontFamily: 'Kanit' }}
      footer={null}
    >
      <div style={{ fontSize: '20px', textAlign: 'center' }}>รายละเอียดบิล</div>
      {!bill ?
        <Spin />
        : <>
          <Row style={{ marginTop: '10px' }}>
            <Col span={12} style={{ fontFamily: 'Kanit' }}>
              #{bill?.bill_number}
            </Col>
            <Col span={12} style={{ fontFamily: 'Kanit' }} align='right'>
              {moment(bill?.bet_at).format('DD/MM/YY HH:mm')}
            </Col>
          </Row>
          <div>{`${bill?.first_name} ${bill?.last_name}`}</div>
          <div style={{ color: '#6C6C6C' }}>{`${bill?.username}`}</div>
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ color: '#6C6C6C' }}>{`#${bill?.type}`}</div>
            <div></div>
            <Tag color={'blue'}>{`${bill?.status}`}</Tag>
          </div>
          {bill?.selected_matches.map((match) => {
            return (
              <Card
                style={{
                  background: '#D9D9D9',
                  marginTop: '10px',
                  fontFamily: 'Kanit',
                }}
              >
                <div>{moment(match.match_id.match_start_at).format('HH:mm')}</div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div
                    style={{
                      color:
                        match.selected_team === match.handicap_team
                          ? '#FF3232'
                          : '',
                    }}
                  >
                    {match.selected_team}
                  </div>
                  <div>{getShowHandicapScore(match.handicap_score)}</div>
                </div>
                <div>
                  <span
                    style={{
                      color:
                        match.match_id.team_a === match.handicap_team
                          ? '#FF3232'
                          : '',
                    }}
                  >
                    {match.match_id.team_a}
                  </span>
                  <span>{` -vs- `}</span>
                  <span
                    style={{
                      color:
                        match.match_id.team_b === match.handicap_team
                          ? '#FF3232'
                          : '',
                    }}
                    ƒ
                  >
                    {match.match_id.team_b}
                  </span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>ผลการแข่งขัน</div>
                  <div>
                    {match.match_id.is_cancelled
                      ? 'ยกเลิก'
                      : match.match_id.score_a >= 0
                        ? `${match.match_id.score_a}-${match.match_id.score_b}`
                        : '-'}
                  </div>
                </div>
              </Card>
            );
          })}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
              fontSize: '20px',
            }}
          >
            <div>ยอดทั้งหมด</div>
            <div>{`${bill?.bet_credits} บาท`}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
              fontSize: '20px',
            }}
          >
            <div>ยอดที่ได้รับ</div>
            <div>{`${bill?.reward >= 0 || bill?.reward < 0 ? bill?.reward : '-'
              } บาท`}</div>
          </div>
        </>}
    </Modal>
  );

  return (
    <div className='container'>
      {detailModal}
      {pageContent}
    </div>
  );
}

export default BillPage;
