import { Row } from 'antd';
import React from 'react';
import slt1 from '../../assets/images/games/slt1.png';
import slt2 from '../../assets/images/games/slt2.png';
import slt3 from '../../assets/images/games/slt3.png';
import slt4 from '../../assets/images/games/slt4.png';
import slt5 from '../../assets/images/games/slt5.png';
import slt6 from '../../assets/images/games/slt6.png';
import slt7 from '../../assets/images/games/slt7.png';
import slt8 from '../../assets/images/games/slt8.png';
import slt9 from '../../assets/images/games/slt9.png';
import slt10 from '../../assets/images/games/slt10.png';
import slt11 from '../../assets/images/games/slt11.png';
import slt12 from '../../assets/images/games/slt12.png';
import GameButton from './GameButton';

function SlotContentList() {

  return (
    <div className='roll-out'>
      <Row gutter={[13, 8]} style={{ marginLeft: '5px', marginTop: '10px' }}>
        <GameButton icon={slt1} />
        <GameButton icon={slt2} />
        <GameButton icon={slt3} />
        <GameButton icon={slt4} />
        <GameButton icon={slt5} />
        <GameButton icon={slt6} />
        <GameButton icon={slt7} />
        <GameButton icon={slt8} />
        <GameButton icon={slt9} />
        <GameButton icon={slt10} />
        <GameButton icon={slt11} />
        <GameButton icon={slt12} />
      </Row>
    </div>
  );
}

export default SlotContentList;
