import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Input,
  Modal,
  Form,
  message,
  Card,
  Col,
  Row,
  Switch,
  Select,
  Radio,
  Avatar,
} from 'antd';
import { useTable } from '../../hooks';
import { useHistory } from 'react-router';
import './index.css';
import api from '../../api';
import moment from 'moment';
import kbank from '../../assets/bank/kbank.svg';
import editIcon from '../../assets/icons/edit.svg';
import binIcon from '../../assets/icons/bin.svg';
import key from '../../assets/icons/key.svg';
import { getNumberDisplayWithCommaNFloating } from '../../utils/string';
import { BANKOPTIONS, getBanklogo } from '../../constants/bank';
import { useForm } from 'antd/es/form/Form';
import { adminErrorHandler } from '../../api/admin';

const { Search } = Input;

function AdminBankPage() {
  useEffect(() => { }, []);

  const history = useHistory();

  const bankOptions = BANKOPTIONS;

  const [loading, setLoading] = useState();
  const [search, setSearch] = useState();
  const [addModalOpen, setAddModalOpen] = useState();
  const [editModalOpen, setEditModalOpen] = useState();
  const [toggleStatusModalOpen, setToggleStatusModalOpen] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState();
  const [selectedBank, setSelectedBank] = useState();

  const [form] = Form.useForm();

  const { tableProps } = useTable(() => {
    return api.admin.getBankList({
      keyword: search,
    });
  }, [search, loading]);

  const showAddModal = (value) => {
    setAddModalOpen(true);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };
  const showEditModal = (bank) => {
    form.setFieldsValue(bank);
    setSelectedBank(bank);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const showToggleStatusModal = (bank) => {
    setSelectedBank(bank);
    setToggleStatusModalOpen(true);
  };

  const closeToggleStatusModal = () => {
    setToggleStatusModalOpen(false);
  };

  const showDeleteModal = (bank) => {
    setSelectedBank(bank);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const onAddBank = async (value) => {
    setLoading(false);
    await api.admin
      .addBank(value)
      .then((res) => {
        message.success('เพิ่มธนาคารสำเร็จ');
        closeAddModal();
      })
      .catch((err) => {
        adminErrorHandler(err);
        message.error('เกิดข้อผิดพลาด')
      });
    setLoading(true);
  };

  const onEditBank = async (value) => {
    setLoading(false);
    await api.admin
      .editBank(value, selectedBank._id)
      .then((res) => {
        message.success('แก้ไขธนาคารสำเร็จ');
        closeEditModal();
      })
      .catch((err) => {
        adminErrorHandler(err);
        message.error('เกิดข้อผิดพลาด')
      });
    setLoading(true);
  };
  const lockBank = async () => {
    setLoading(false);
    await api.admin
      .lockBank(selectedBank._id)
      .then((res) => {
        message.success('ระงับธนาคารสำเร็จ');
        closeToggleStatusModal();
      })
      .catch((err) => {
        adminErrorHandler(err);
        message.error('เกิดข้อผิดพลาด')
      });
    setLoading(true);
  };

  const unlockBank = async () => {
    setLoading(false);
    await api.admin
      .unlockBank(selectedBank._id)
      .then((res) => {
        message.success('เปิดใช้งานธนาคารสำเร็จ');
        closeToggleStatusModal();
      })
      .catch((err) => {
        adminErrorHandler(err);
        message.error('เกิดข้อผิดพลาด')
      });
    setLoading(true);
  };

  const deleteBank = async () => {
    setLoading(false);
    await api.admin
      .deleteBank(selectedBank._id)
      .then((res) => {
        message.success('ลบธนาคารสำเร็จ');
        closeDeleteModal();
      })
      .catch((err) => {
        adminErrorHandler(err);
        message.error('เกิดข้อผิดพลาด')
      });
    setLoading(true);
  };

  const [type, setType] = useState(1);
  const onChange = (e) => {
    setType(e.target.value);
  };

  const tabledata = tableProps?.dataSource?.data;

  const depositList = tabledata?.map((value, index) => {
    if (value.type === 'deposit') {
      return (
        <Col span={6}>
          <Card
            actions={[
              <Switch
                checked={value.isOpen}
                onClick={() => showToggleStatusModal(value)}
              />,
              <img onClick={() => showEditModal(value)} src={editIcon} />,
              <img onClick={() => showDeleteModal(value)} src={binIcon} />,
            ]}
          >
            <div style={{ display: 'flex' }}>
              <Avatar src={getBanklogo(value.bank)} size={45}></Avatar>
              <p
                style={{
                  fontSize: '16px',
                  fontFamily: 'Kanit',
                  fontStyle: 'bold',
                }}
              >
                {value.bank}
              </p>
            </div>
            <Row style={{ marginTop: '10px' }}>
              <Col span={12}>
                <div>{`${value.first_name} ${value.last_name}`}</div>
                <div>{value.bank_no}</div>
              </Col>
              <Col span={12} align='right'>
                <div>จำนวนเงิน</div>
                <div>{value.credits}</div>
              </Col>
            </Row>
          </Card>
        </Col>
      );
    } else return;
  });

  const withdrawList = tabledata?.map((value, index) => {
    if (value.type === 'withdraw') {
      return (
        <Col span={6}>
          <Card
            actions={[
              <Switch
                checked={value.isOpen}
                onClick={() => showToggleStatusModal(value)}
              />,
              <img onClick={() => showEditModal(value)} src={editIcon} />,
              <img onClick={() => showDeleteModal(value)} src={binIcon} />,
            ]}
          >
            <div style={{ display: 'flex' }}>
              <Avatar src={getBanklogo(value.bank)} size={45}></Avatar>
              <p
                style={{
                  fontSize: '16px',
                  fontFamily: 'Kanit',
                  fontStyle: 'bold',
                }}
              >
                {value.bank}
              </p>
            </div>
            <Row style={{ marginTop: '10px' }}>
              <Col span={12}>
                <div>{`${value.first_name} ${value.last_name}`}</div>
                <div>{value.bank_no}</div>
              </Col>
              <Col span={12} align='right'>
                <div>จำนวนเงิน</div>
                <div>{value.credits}</div>
              </Col>
            </Row>
          </Card>
        </Col>
      );
    } else return;
  });

  const toggleStatusModal = (
    <Modal
      open={toggleStatusModalOpen}
      onClose={closeToggleStatusModal}
      onCancel={closeToggleStatusModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      {selectedBank && (
        <React.Fragment>
          {selectedBank.isOpen ? (
            <div style={{ fontSize: '20px' }}>ระงับการใช้งาน</div>
          ) : (
            <div style={{ fontSize: '20px' }}>คืนสถานะการใช้งาน</div>
          )}
          <div>{`${selectedBank.first_name} ${selectedBank.last_name}`}</div>
          <div>{`${selectedBank.bank_no}`}</div>
          {selectedBank.isOpen ? (
            <div style={{ fontSize: '20px' }}>คุณต้องการระงับธนาคารหรือไม่</div>
          ) : (
            <div style={{ fontSize: '20px' }}>
              คุณต้องการเปิดใช้งานธนาคารหรือไม่
            </div>
          )}
        </React.Fragment>
      )}
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={selectedBank?.isOpen ? lockBank : unlockBank}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeToggleStatusModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{ background: '#F7D249', color: 'black' }}
            >
              ตกลง
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const deleteModal = (
    <Modal
      open={deleteModalOpen}
      onClose={closeDeleteModal}
      onCancel={closeDeleteModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>ลบธนาคาร</div>
      {selectedBank && (
        <React.Fragment>
          <div>{`${selectedBank.bank}`}</div>
          <div>{`${selectedBank.bank_no}`}</div>
          <div>{`${selectedBank.first_name} ${selectedBank.last_name}`}</div>
        </React.Fragment>
      )}
      <div style={{ fontSize: '20px' }}>คุณต้องการลบธนาคารหรือไม่</div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={deleteBank}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeDeleteModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{ background: '#27AA85' }}
            >
              ตกลง
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const addModal = (
    <Modal
      open={addModalOpen}
      onClose={closeAddModal}
      onCancel={closeAddModal}
      style={{ fontFamily: 'Kanit' }}
      footer={null}
    >
      <div style={{ fontSize: '30px' }}>สร้างบัญชีธนาคาร</div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={onAddBank}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <Form.Item
          label='สถานะการใช้งาน'
          name='isOpen'
          required
          valuePropName='checked'
        >
          <Switch placeholder='สถานะการใช้งาน' />
        </Form.Item>
        <Form.Item label='ธนาคาร' name='bank' required>
          <Select placeholder='เลือกธนาคาร' options={bankOptions}></Select>
        </Form.Item>
        <Form.Item label='เลขที่บัญชี' name='bank_no' required>
          <Input placeholder='เลขที่บัญชี' />
        </Form.Item>
        <Form.Item label='ชื่อจริง' name='first_name' required>
          <Input placeholder='ชื่อจริง' />
        </Form.Item>
        <Form.Item label='นามสกุล' name='last_name' required>
          <Input placeholder='นามสกุล' />
        </Form.Item>

        <Form.Item label='ยอดเงินในบัญชี' name='credits' required>
          <Input type='number' placeholder='ยอดเงินในบัญชี' />
        </Form.Item>
        <Form.Item label='ประเภทบัญชี' name='type' required>
          <Radio.Group onChange={onChange} value={type}>
            <Radio value={'deposit'}>บัญชีสำหรับการฝาก</Radio>
            <Radio value={'withdraw'}>บัญชีสำหรับการถอน</Radio>
          </Radio.Group>
        </Form.Item>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeAddModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{ background: '#F7D249', color: 'black' }}
            >
              เพิ่ม
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const editModal = (
    <Modal
      open={editModalOpen}
      onClose={closeEditModal}
      onCancel={closeEditModal}
      style={{ fontFamily: 'Kanit' }}
      footer={null}
    >
      <div style={{ fontSize: '30px' }}>แก้ไขบัญชีธนาคาร</div>
      <Form
        form={form}
        initialValues={selectedBank}
        onFinish={onEditBank}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <Form.Item
          label='สถานะการใช้งาน'
          name='isOpen'
          required
          valuePropName='checked'
        >
          <Switch placeholder='สถานะการใช้งาน' />
        </Form.Item>
        <Form.Item label='ธนาคาร' name='bank' required>
          <Select placeholder='เลือกธนาคาร' options={bankOptions}></Select>
        </Form.Item>
        <Form.Item label='เลขที่บัญชี' name='bank_no' required>
          <Input placeholder='เลขที่บัญชี' />
        </Form.Item>
        <Form.Item label='ชื่อจริง' name='first_name' required>
          <Input placeholder='ชื่อจริง' />
        </Form.Item>
        <Form.Item label='นามสกุล' name='last_name' required>
          <Input placeholder='นามสกุล' />
        </Form.Item>

        <Form.Item label='ยอดเงินในบัญชี' name='credits' required>
          <Input type='number' placeholder='ยอดเงินในบัญชี' />
        </Form.Item>
        <Form.Item label='ประเภทบัญชี' name='type' required>
          <Radio.Group onChange={onChange} value={type}>
            <Radio value={'deposit'}>บัญชีสำหรับการฝาก</Radio>
            <Radio value={'withdraw'}>บัญชีสำหรับการถอน</Radio>
          </Radio.Group>
        </Form.Item>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeEditModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{ background: '#F7D249', color: 'black' }}
            >
              แก้ไข
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  return (
    <div>
      {addModal}
      {editModal}
      {toggleStatusModal}
      {deleteModal}
      <div style={{ fontSize: '30px' }}>จัดการธนาคาร</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Search
          onSearch={setSearch}
          placeholder='ค้นหาธนาคาร, ชื่อบัญชี, เลขที่บัญชี'
          style={{ width: '30%' }}
        ></Search>
        <Button
          placeholder=''
          style={{ background: '#F7D249' }}
          onClick={showAddModal}
        >
          + เพิ่มธนาคาร
        </Button>
      </div>
      <div style={{ fontSize: '20px' }}>บัญชีสำหรับฝาก</div>
      <Row gutter={16}>{depositList}</Row>
      <div style={{ fontSize: '20px' }}>บัญชีสำหรับถอน</div>
      <Row gutter={16}>{withdrawList}</Row>
    </div>
  );
}

export default AdminBankPage;
