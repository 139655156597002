import { Row } from 'antd';
import React from 'react';
import itp1 from '../../assets/images/games/itp1.png';
import itp2 from '../../assets/images/games/itp2.png';
import itp3 from '../../assets/images/games/itp3.png';
import itp4 from '../../assets/images/games/itp4.png';
import itp5 from '../../assets/images/games/itp5.png';
import itp6 from '../../assets/images/games/itp6.png';
import itp7 from '../../assets/images/games/itp7.png';
import itp8 from '../../assets/images/games/itp8.png';
import itp9 from '../../assets/images/games/itp9.png';
import GameButton from './GameButton';

function ITPContentList() {

  return (
    <div className='roll-out'>
      <Row gutter={[13, 8]} style={{ marginLeft: '5px', marginTop: '10px' }}>
        <GameButton icon={itp1} />
        <GameButton icon={itp2} />
        <GameButton icon={itp3} />
        <GameButton icon={itp4} />
        <GameButton icon={itp5} />
        <GameButton icon={itp6} />
        <GameButton icon={itp7} />
        <GameButton icon={itp8} />
        <GameButton icon={itp9} />
      </Row>
    </div>
  );
}

export default ITPContentList;
