import axios from 'axios';

const api_path = process.env.REACT_APP_API;

const token = localStorage.getItem('token');

const headers = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + token
  ,
};

export const userErrorHandler = (err) => {
  if (err?.response?.status === 401) {
    window.location.replace('/');
  }
};

const UserApi = {
  login: (data) => {
    return axios.post(`${api_path}/login`, data)
  },

  changePassword: async (data, id) => {
    const response = await axios
      .post(`${api_path}/api/user/changepassword/${id}`, data, { headers })

    return response;
  },

  getUserDetail: async (username) => {
    const response = await axios
      .get(`${api_path}/api/user/${username}`, { headers })

    return response;
  },

  getHandicapList: async (date) => {
    return axios
      .post(`${api_path}/api/handicap`, date, { headers })
  },

  getStepList: async (date) => {
    return axios
      .post(`${api_path}/api/step`, date, { headers })
  },

  deposit: async (data, username) => {
    const response = await axios
      .post(`${api_path}/api/deposit/${username}`, data, { headers })

    return response;
  },

  withdraw: async (data, username) => {
    const response = await axios
      .post(`${api_path}/api/withdraw/${username}`, data, { headers })
    return response;
  },

  betHandicap: async (data) => {
    const response = await axios
      .post(`${api_path}/api/bet/handicap`, data, { headers })

    return response;
  },

  betStep: async (data) => {
    const response = await axios
      .post(`${api_path}/api/bet/step`, data, { headers })

    return response;
  },

  getBillList: async (input) => {
    return axios
      .post(`${api_path}/api/bill`, input, { headers })
  },

  getDepositList: async (input) => {
    return axios
      .post(`${api_path}/api/GetDepositList`, input, { headers })
  },

  getWithdrawList: async (input) => {
    return axios
      .post(`${api_path}/api/GetWithdrawList`, input, { headers })
  },

  getBank: async () => {
    const response = await axios
      .get(`${api_path}/api/bank`, { headers })

    return response;
  },

  getHandicapBill: async (input) => {
    return axios
      .post(`${api_path}/api/handicapBill`, input, { headers })
  },

  getStepBill: async (input) => {
    return axios
      .post(`${api_path}/api/stepBill`, input, { headers })
  },

  getBillDetail: async (id) => {
    return axios
      .post(`${api_path}/api/billDetail`, { id }, { headers })
  },
};

export default UserApi;
