import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Input,
  Modal,
  Form,
  message,
  Select,
  Space,
  DatePicker,
  Switch,
  Row,
  Col,
  Divider,
  Card,
  Tag,
  Menu,
  Spin,
} from 'antd';
import { useDataState, useTable } from '../../hooks';
import { useHistory } from 'react-router';
import './index.css';
import api from '../../api';
import moment from 'moment';
import kbank from '../../assets/bank/kbank.svg';
import searchIcon from '../../assets/icons/search.svg';
import announce from '../../assets/icons/announce.svg';
import unlock from '../../assets/icons/unlock.svg';
import key from '../../assets/icons/key.svg';

import { getNumberDisplayWithCommaNFloating } from '../../utils/string';
import { BANK } from '../../constants/bank';
import { getShowHandicapScore } from '../../constants/handicap';
import { adminErrorHandler } from '../../api/admin';

const { Search } = Input;
const { RangePicker } = DatePicker;

function getItem(label, key, path, children, type) {
  return {
    key,
    children,
    label,
    type,
    path,
  };
}

function AdminBillPage() {
  useEffect(() => { }, []);

  const history = useHistory();
  const [form] = Form.useForm();
  const admin = localStorage.getItem('admin');
  const billData = useDataState();

  const [loading, setLoading] = useState();
  const [date, setDate] = useState([moment(), moment()]);
  const [search, setSearch] = useState();
  const [type, setType] = useState('บอลสเต็ป');
  const [addModalOpen, setAddModalOpen] = useState();
  const [announceModalOpen, setAnnounceModalOpen] = useState();
  const [selectedBill, setSelectedBill] = useState();
  const [detailModalOpen, setDetailModalOpen] = useState();

  const { tableProps } = useTable(() => {
    return api.admin.getBillList({
      keyword: search,
      date: date,
      type: type
    });
  }, [search, loading, date, type]);

  useEffect(() => {
    const id = selectedBill?._id;
    const promise = api.admin.getBillDetail(id);
    billData.load(Promise.resolve(promise).then((value) => value));
  }, [selectedBill]);

  const onDateChange = (value) => {
    setDate(value || [moment(), moment()]);
  };

  const showAddModal = (value) => {
    setAddModalOpen(true);
  };

  const showDetail = (value) => {
    setSelectedBill(value);
    setDetailModalOpen(true);
  };

  const closeDetail = () => {
    setDetailModalOpen(false);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  const showAnnounceModal = (user) => {
    setSelectedBill(user);
    setAnnounceModalOpen(true);
  };

  const closeAnnounceModal = () => {
    setAnnounceModalOpen(false);
  };

  const onAddHandicap = async (value) => {
    setLoading(false);

    await api.admin
      .addHandicap(value.match)
      .then((res) => {
        message.success('เพิ่มคู่บอลสำเร็จ');
        closeAddModal();
      })
      .catch((err) => {
        adminErrorHandler(err);
        message.error('เกิดข้อผิดพลาด')
      });
    setLoading(true);
  };

  const onAnnounce = async (value) => {
    setLoading(false);
    await api.admin
      .announceHandicap(value, selectedBill._id)
      .then((res) => {
        message.success('ประกาศผลสำเร็จ');
        closeAnnounceModal();
      })
      .catch((err) => {
        adminErrorHandler(err);
        message.error('เกิดข้อผิดพลาด')
      });
    setLoading(true);
  };

  const onSelectMenu = (value) => {
    const { key } = value;
    setType(key);
  };


  const tabledata = tableProps?.dataSource?.data;
  const bill = billData?.data?.data;

  const columns = [
    {
      title: 'รหัสบิล',
      dataIndex: 'bill_number',
      key: 'bill_number',
    },
    {
      title: 'วัน/เวลาที่เล่น',
      dataIndex: 'bet_at',
      key: 'bet_at',
      render: (data, row, index) => {
        return moment(data).format('DD/MM/YYYY HH:mm');
      },
      sorter: (a, b) => new Date(a.bet_at) - new Date(b.bet_at),
    },
    {
      title: 'ประเภทบอล',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'ชื่อผู้ใช้งาน',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'ยอดแทงบอล',
      dataIndex: 'bet_credits',
      key: 'bet_credits',
      render: (data, row, index) => {
        return <p>{getNumberDisplayWithCommaNFloating(data)}</p>;
      },
    },
    {
      title: 'ยอดที่ได้รับ',
      dataIndex: 'reward',
      key: 'reward',
      render: (data, row, index) => {
        return <p>{getNumberDisplayWithCommaNFloating(data) || 0.0}</p>;
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      render: (data, row, index) => {
        return <p>{data}</p>;
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (_, row, index) => {
        return (
          <div style={{ display: 'flex' }}>
            <img onClick={() => showDetail(row)} src={searchIcon}></img>
          </div>
        );
      },
    },
  ];

  const announceModal = (
    <Modal
      open={announceModalOpen}
      onClose={closeAnnounceModal}
      onCancel={closeAnnounceModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={onAnnounce}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div style={{ fontSize: '20px', marginBottom: ' 20px' }}>
          ประกาศผลบอลเต็ง รอบวันที่{' '}
          {moment(selectedBill?.match_start_at).format('DD/MM/YYYY')}
        </div>
        {selectedBill && (
          <div>
            <Row>
              <Col span={4}>
                {moment(selectedBill?.match_start_at).format('HH:mm')}
              </Col>
              <Col span={5} align='right'>
                <div style={{ color: '#FF3232' }}>{selectedBill.team_a}</div>
              </Col>
              <Col span={5}>
                <Form.Item
                  name='score_a'
                  rules={[{ required: true, message: 'Missing value' }]}
                >
                  <Input type='number' />
                </Form.Item>
              </Col>
              <Col span={5} align='right'>
                <div>{selectedBill.team_b}</div>
              </Col>
              <Col span={5}>
                <Form.Item
                  name='score_b'
                  rules={[{ required: true, message: 'Missing value' }]}
                >
                  <Input type='number' />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeAnnounceModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{ background: '#2D2D2D' }}
            >
              ประกาศผล
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const detailModal = (
    <Modal
      open={detailModalOpen}
      onClose={closeDetail}
      onCancel={closeDetail}
      style={{ fontFamily: 'Kanit' }}
      footer={null}
    >
      <div style={{ fontSize: '20px', textAlign: 'center' }}>รายละเอียดบิล</div>
      {!bill ?
        <Spin />
        : <>
          <Row style={{ marginTop: '10px' }}>
            <Col span={12} style={{ fontFamily: 'Kanit' }}>
              #{bill?.bill_number}
            </Col>
            <Col span={12} style={{ fontFamily: 'Kanit' }} align='right'>
              {moment(bill?.bet_at).format('DD/MM/YY HH:mm')}
            </Col>
          </Row>
          <div>{`${bill?.first_name} ${bill?.last_name}`}</div>
          <div style={{ color: '#6C6C6C' }}>{`${bill?.username}`}</div>
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ color: '#6C6C6C' }}>{`#${bill?.type}`}</div>
            <div></div>
            <Tag color={'blue'}>{`${bill?.status}`}</Tag>
          </div>
          {bill?.selected_matches.map((match) => {
            return (
              <Card
                style={{
                  background: '#D9D9D9',
                  marginTop: '10px',
                  fontFamily: 'Kanit',
                }}
              >
                <div>{moment(match.match_id.match_start_at).format('HH:mm')}</div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div
                    style={{
                      color:
                        match.selected_team === match.handicap_team
                          ? '#FF3232'
                          : '',
                    }}
                  >
                    {match.selected_team}
                  </div>
                  <div>{getShowHandicapScore(match.handicap_score)}</div>
                </div>
                <div>
                  <span
                    style={{
                      color:
                        match.match_id.team_a === match.handicap_team
                          ? '#FF3232'
                          : '',
                    }}
                  >
                    {match.match_id.team_a}
                  </span>
                  <span>{` -vs- `}</span>
                  <span
                    style={{
                      color:
                        match.match_id.team_b === match.handicap_team
                          ? '#FF3232'
                          : '',
                    }}
                    ƒ
                  >
                    {match.match_id.team_b}
                  </span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>ผลการแข่งขัน</div>
                  <div>
                    {match.match_id.is_cancelled
                      ? 'ยกเลิก'
                      : match.match_id.score_a >= 0
                        ? `${match.match_id.score_a}-${match.match_id.score_b}`
                        : '-'}
                  </div>
                </div>
              </Card>
            );
          })}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
              fontSize: '20px',
            }}
          >
            <div>ยอดทั้งหมด</div>
            <div>{`${bill?.bet_credits} บาท`}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
              fontSize: '20px',
            }}
          >
            <div>ยอดที่ได้รับ</div>
            <div>{`${bill?.reward >= 0 || bill?.reward < 0 ? bill?.reward : '-'
              } บาท`}</div>
          </div>
        </>}
    </Modal>
  );

  const menuItems = [
    getItem('บอลสเต็ป', 'บอลสเต็ป'),
    getItem('บอลเต็ง', 'บอลเต็ง'),
  ];

  return (
    <div>
      {detailModal}
      {announceModal}
      <div style={{ fontSize: '30px' }}>บิลทั้งหมด</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Search
          onSearch={setSearch}
          placeholder='ค้นหารหัสบิล, ประเภทบอล, ชื่อผู้ใช้'
          style={{ width: '30%' }}
        ></Search>
        <RangePicker onChange={onDateChange}></RangePicker>
      </div>
      <Menu
        mode='horizontal'
        selectedKeys={[type]}
        items={menuItems}
        onSelect={onSelectMenu}
      ></Menu>
      <Table dataSource={tabledata} columns={columns}></Table>
    </div>
  );
}

export default AdminBillPage;
