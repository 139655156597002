import React, { useState, useEffect } from 'react';
import { Popover, Menu, Form, Input, Button, message, Modal } from 'antd';
import { useHistory } from 'react-router';
import hot from '../../assets/images/hot.png';
import langlayout from '../../assets/images/langlayout.png';
import logo from '../../assets/images/logo.png';
import { DownOutlined, PieChartOutlined } from '@ant-design/icons';
import './index.css';
import api from '../../api';
import { Redirect } from 'react-router';
import { useDataState } from '../../hooks';
import { getNumberDisplayWithCommaNFloating } from '../../utils/string';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

function getItem(label, key, icon, path, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
    path,
  };
}

function getPath(key) {
  const list = ['/step', '/handicap', '/bill', '/deposithistory'];
  return list[key];
}

const items = [
  getItem('บอลสเต็ป', '0', null, '/step'),
  getItem('บอลเต็ง', '1', null, '/handicap'),
  getItem('ประวัติการเล่น', '2', null, '/bill'),
  getItem('ฝาก/ถอน', '3', null, '/deposit'),
];

function Layout({ children }) {
  const username = localStorage.getItem('username');
  const userData = useDataState();
  const [selectedKey, setSelectedKey] = useState(0);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState();

  const [resetPasswordform] = Form.useForm();
  const [loading, setLoading] = useState();
  const location = useLocation();

  const currentPath = location.pathname;

  useEffect(() => {
    const promise = api.user.getUserDetail(username);
    userData.load(Promise.resolve(promise).then((value) => value));
  }, [loading]);

  const onClick = (e) => {
    setLoading(false);
    setSelectedKey(e.key);
    history.push(getPath(e.key));
    setLoading(true);
  };

  const goToPage = (page) => {
    history.push(page);
  };

  const user = userData?.data?.data;

  const history = useHistory();

  const onLogout = () => {
    localStorage.clear();
    history.push('/');
  };

  const showChangePasswordModal = () => {
    setChangePasswordModalOpen(true);
  };

  const closeChangePasswordModal = () => {
    setChangePasswordModalOpen(false);
  };

  const changePassword = async (value) => {
    setLoading(true);
    await api.user
      .changePassword({ password: value.password }, user._id)
      .then((res) => {
        message.success('เปลี่ยนรหัสผ่านสำเร็จ');
        closeChangePasswordModal();
        resetPasswordform.resetFields();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error('เกิดข้อผิดพลาด');
      });
  };

  const settingsContent = (
    <Menu>
      <Menu.Item onClick={showChangePasswordModal}>เปลี่ยนรหัสผ่าน</Menu.Item>
    </Menu>
  );

  const changePasswordModal = (
    <Modal
      open={changePasswordModalOpen}
      onClose={closeChangePasswordModal}
      onCancel={closeChangePasswordModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>เปลี่ยนรหัสผ่าน</div>
      {user && (
        <React.Fragment>
          <div>{`${user.first_name} ${user.last_name}`}</div>
          <div>{`${user.phone}`}</div>
        </React.Fragment>
      )}
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={changePassword}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
        form={resetPasswordform}
      >
        <Form.Item
          name='password'
          required
          rules={[{ required: true, message: 'Missing value' }]}
        >
          <Input placeholder='รหัสผ่าน' />
        </Form.Item>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeChangePasswordModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={loading}
              style={{ background: '#F7D249' }}
            >
              เปลี่ยนรหัสผ่าน
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=0.4, user-scalable=yes" />
      <div className='container' style={{ width: 'auto' }}>
        {changePasswordModal}
        <body>
          <header className='topbar'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <img style={{ width: '226px' }} src={logo}></img>
                <div style={{ display: 'flex' }}>
                  <a className='menu-top-btn' style={{ textDecoration: 'none' }} href='https://member.ufabu789.com/login?redirect=/'>
                    Ufabu789
                    <img
                      src={hot}
                      style={{ width: '20px', marginLeft: '10px', transform: 'rotate(-45deg)' }}
                    ></img>
                  </a>
                  <button className='menu-top-btn' style={{ color: currentPath === '/step' ? '#FC0' : '' }} onClick={() => { goToPage('/step') }}>บอลสเต็ป</button>
                  <button className='menu-top-btn' style={{ color: currentPath === '/handicap' ? '#FC0' : '' }} onClick={() => { goToPage('/handicap') }}>บอลเต็ง</button>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginRight: '5px' }}>
                <div className='text' style={{ color: '#fff', alignItems: 'center' }}>
                  {`${moment().format('DD-MM-YYYY LT')} (GMT+7:00)`}
                </div>
                <img src={langlayout} height={'20px'} />
                <div>
                  <div className='user-box'>
                    <div style={{ textAlign: 'center' }}>ยินดีต้อนรับ: <span style={{ fontWeight: 'bold' }}>{username}</span></div>
                    <div style={{ textAlign: 'center' }}>
                      เครดิต:{' '}
                      <span style={{ fontWeight: 'bold' }}>{getNumberDisplayWithCommaNFloating(user?.credits || 0)}</span>
                    </div>
                  </div>
                  <div style={{
                    color: '#fff',
                    backgroundColor: '#73582a',
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '5px',
                    alignItems: 'center'
                  }}>
                    <Popover content={settingsContent}>
                      <div style={{ fontSize: '13px', fontFamily: 'Arial' }}>การตั้งค่า </div>
                    </Popover>
                    <div>|</div>
                    <div style={{ fontSize: '13px', fontFamily: 'Arial' }} onClick={onLogout}>ออกจากระบบ</div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{
              display: 'flex',
              backgroundColor: '#745929',
              alignItems: 'center',
              paddingLeft: '20px',
              gap: '10px',
              paddingTop: '5px',
              paddingBottom: '5px'
            }}>
              <div className='menu-bottom' onClick={() => { goToPage('/bill') }}
                style={{ color: currentPath === '/bill' ? '#FC0' : '#fff', }}>ประวัติการเล่น</div>
              <button className='menu-bottom-btn' style={{
                color: currentPath === '/deposithistory' ? '#FC0' : '#fff',
                background: 'linear-gradient(to bottom, #6ef390, #3aa558)'
              }} onClick={() => { goToPage('/deposithistory') }}>ฝาก</button>
              <button className='menu-bottom-btn' style={{
                color: currentPath === '/withdrawhistory' ? '#FC0' : '#fff',
                background: 'linear-gradient(to bottom, #f46f5b, #d63937)'
              }} onClick={() => { goToPage('/withdrawhistory') }}>ถอน</button>
            </div>
          </header>
          <div className='mobile-page-content'>{children}</div>
        </body>
      </div>
    </>
  );
}

export default Layout;
