import React from 'react';

function MenuButton(props) {
  const { isChecked = false, icon, checkedIcon, title, onClick } = props

  return (
    <div className={isChecked ? 'menu-button-checked' : 'menu-button'} onClick={onClick}>
      {isChecked ?
        <img src={checkedIcon} style={{ width: ' 42px', margin: 'auto' }} />
        : <img src={icon} style={{ width: ' 30px', margin: 'auto', marginTop: '5px', marginBottom: '5px' }} />
      }
      <div className={isChecked ? 'menu-text-checked' : 'menu-text'}>{title}</div>
    </div>
  );
}

export default MenuButton;
