import React, { useState, useEffect } from 'react';
import './App.css';
import AdminLoginPage from './pagesadmin/Login';
import AdminDashboardPage from './pagesadmin/Dashboard';
import AdminUserPage from './pagesadmin/User';
import AdminBankPage from './pagesadmin/Bank';
import AdminHandicapPage from './pagesadmin/Handicap';
import LoginPage from './pagesuser/Login';
import StepPage from './pagesuser/Step';
import HandicapPage from './pagesuser/Handicap';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import MobileRoute from './MobileRoute';
import AdminRoute from './AdminRoute';
import BankPage from './pagesuser/Bank';
import AdminDepositPage from './pagesadmin/Deposit';
import AdminWithdrawPage from './pagesadmin/Withdraw';
import AdminBillPage from './pagesadmin/Bill';
import AdminStepPage from './pagesadmin/Step';
import BillPage from './pagesuser/Bill';
import AgreementPage from './pagesuser/Agreement';
import AdminAdminPage from './pagesadmin/Admin';
import LandingPage from './pagesuser/Landing';
import WithdrawPage from './pagesuser/Bank/WithdrawPage';

const base_path = process.env.REACT_APP_BASE_PATH;

function App() {
    useEffect(() => { }, []);
    const userToken = localStorage.getItem('token')
    const adminToken = localStorage.getItem('adminToken')

    return (
        <BrowserRouter basename='/'>
            <Switch>
                <Route path='/' exact component={LandingPage}></Route>
                <Route path='/login' exact component={LoginPage}></Route>
                <Route path='/admin' exact component={AdminLoginPage}></Route>
                <Route path='/agreement' exact component={AgreementPage}></Route>
                {adminToken && (
                    <AdminRoute path='/dashboard' exact component={AdminDashboardPage}></AdminRoute>
                )}
                {adminToken && (
                    <AdminRoute path='/user' exact component={AdminUserPage}></AdminRoute>
                )}
                {adminToken && (
                    <AdminRoute path='/bank' exact component={AdminBankPage}></AdminRoute>
                )}
                {adminToken && (
                    <AdminRoute path='/deposit' exact component={AdminDepositPage}></AdminRoute>
                )}
                {adminToken && (
                    <AdminRoute path='/withdraw' exact component={AdminWithdrawPage}></AdminRoute>
                )}
                {adminToken && (
                    <AdminRoute path='/adminhandicap' exact component={AdminHandicapPage}></AdminRoute>
                )}
                {adminToken && (
                    <AdminRoute path='/adminstep' exact component={AdminStepPage}></AdminRoute>
                )}
                {adminToken && (
                    <AdminRoute path='/adminbill' exact component={AdminBillPage}></AdminRoute>
                )}
                {adminToken && (
                    <AdminRoute path='/manageadmin' exact component={AdminAdminPage}></AdminRoute>
                )}
                {userToken && (
                    <MobileRoute path='/step' exact component={StepPage}></MobileRoute>
                )}
                {userToken && (
                    <MobileRoute path='/handicap' exact component={HandicapPage}></MobileRoute>
                )}
                {userToken && (
                    <MobileRoute path='/bill' exact component={BillPage}></MobileRoute>
                )}
                {userToken && (
                    <MobileRoute path='/deposithistory' exact component={BankPage}></MobileRoute>
                )}
                {userToken && (
                    <MobileRoute path='/withdrawhistory' exact component={WithdrawPage}></MobileRoute>
                )}
                {userToken && (
                    <Redirect to='/handicap' />
                )}
                {adminToken && (
                    <Redirect to='/dashboard' />
                )}
                <Redirect to='/' />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
