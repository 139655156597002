import { Row } from 'antd';
import React from 'react';
import lt1 from '../../assets/images/games/lt1.png';
import lt2 from '../../assets/images/games/lt2.png';
import GameButton from './GameButton';

function LottoContentList() {

  return (
    <div className='roll-out'>
      <Row gutter={[13, 8]} style={{ marginLeft: '5px', marginTop: '10px' }}>
        <GameButton colSpan={24} icon={lt1} />
        <GameButton colSpan={24} icon={lt2} />
      </Row>
    </div>
  );
}

export default LottoContentList;
