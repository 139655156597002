import { Row } from 'antd';
import React from 'react';
import cs1 from '../../assets/images/games/cs1.png';
import cs2 from '../../assets/images/games/cs2.png';
import cs3 from '../../assets/images/games/cs3.png';
import cs4 from '../../assets/images/games/cs4.png';
import cs5 from '../../assets/images/games/cs5.png';
import cs6 from '../../assets/images/games/cs6.png';
import cs7 from '../../assets/images/games/cs7.png';
import cs8 from '../../assets/images/games/cs8.png';
import cs9 from '../../assets/images/games/cs9.png';
import cs10 from '../../assets/images/games/cs10.png';
import cs11 from '../../assets/images/games/cs11.png';
import cs12 from '../../assets/images/games/cs12.png';
import GameButton from './GameButton';

function CasinoContentList() {

  return (
    <div className='roll-out'>
      <Row gutter={[13, 8]} style={{ marginLeft: '5px', marginTop: '10px' }}>
        <GameButton icon={cs1} />
        <GameButton icon={cs2} />
        <GameButton icon={cs3} />
        <GameButton icon={cs4} />
        <GameButton icon={cs5} />
        <GameButton icon={cs6} />
        <GameButton icon={cs7} />
        <GameButton icon={cs8} />
        <GameButton icon={cs9} />
        <GameButton icon={cs10} />
        <GameButton icon={cs11} />
        <GameButton icon={cs12} />
      </Row>
    </div>
  );
}

export default CasinoContentList;
