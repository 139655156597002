import React, { useState, useEffect } from 'react';
import { Button, Carousel, Checkbox, Form, Image, Input, message } from 'antd';
import { useHistory } from 'react-router';
import hamburger from '../../assets/icons/hamburger.svg';
import logo from '../../assets/images/logo.png';
import './index.css';
import api from '../../api';
import { userErrorHandler } from '../../api/user';
import Slide1 from '../../assets/images/Slide1.jpg';
import Slide2 from '../../assets/images/Slide2.jpg';
import Slide3 from '../../assets/images/Slide3.jpg';
import Slide4 from '../../assets/images/Slide4.jpg';
import Slide5 from '../../assets/images/Slide5.jpg';
import Slide6 from '../../assets/images/Slide6.jpg';
import Slide7 from '../../assets/images/Slide7.png';
import Slide8 from '../../assets/images/Slide8.jpg';
import Slide9 from '../../assets/images/Slide9.jpg';
import Slide10 from '../../assets/images/Slide10.jpg';
import Running1 from '../../assets/images/running1.gif';
import Running2 from '../../assets/images/running2.gif';
import Running3 from '../../assets/images/running3.gif';
import Running4 from '../../assets/images/running4.gif';
import Running5 from '../../assets/images/running5.gif';
import MenuContent from './MenuContent';


function LandingPage() {
  useEffect(() => { }, []);

  const history = useHistory();

  const gotoLogin = () => {
    history.push('/login')
  }

  const runningImages = <>
    <img src={Running1} className='image-running' />
    <img src={Running2} className='image-running' />
    <img src={Running3} className='image-running' />
    <img src={Running4} className='image-running' />
    <img src={Running5} className='image-running' /></>

  return (
    <div className='container-landing'>
      <div
        style={{
          display: 'flex', justifyContent: 'space-between'
        }}
      >
        <img style={{ width: '10%' }} src={hamburger}></img>
        <img style={{ width: '50%' }} src={logo}></img>
        <div className='login-button' onClick={gotoLogin}>เข้าสู่ระบบ</div>
      </div>
      <Carousel autoplay dots={false} style={{ marginLeft: '11px', marginRight: '11px' }}>
        <img className='image-slide' src={Slide1}></img>
        <img className='image-slide' src={Slide2}></img>
        <img className='image-slide' src={Slide3}></img>
        <img className='image-slide' src={Slide4}></img>
        <img className='image-slide' src={Slide5}></img>
        <img className='image-slide' src={Slide6}></img>
        <img className='image-slide' src={Slide7}></img>
        <img className='image-slide' src={Slide8}></img>
        <img className='image-slide' src={Slide9}></img>
        <img className='image-slide' src={Slide10}></img>
      </Carousel>
      <div class="scroll-container">
        <div class="scroll-wrapper">
          {runningImages}
          {runningImages}
          {runningImages}
        </div>
      </div>
      <MenuContent onLogin={gotoLogin} />
    </div>
  );
}

export default LandingPage;
