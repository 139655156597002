import { Row } from 'antd';
import React from 'react';
import cd1 from '../../assets/images/games/cd1.png';
import cd2 from '../../assets/images/games/cd2.png';
import cd3 from '../../assets/images/games/cd3.png';
import cd4 from '../../assets/images/games/cd4.png';
import cd5 from '../../assets/images/games/cd5.png';
import cd6 from '../../assets/images/games/cd6.png';
import cd7 from '../../assets/images/games/cd7.png';
import cd8 from '../../assets/images/games/cd8.png';
import cd9 from '../../assets/images/games/cd9.png';
import cd10 from '../../assets/images/games/cd10.png';
import cd11 from '../../assets/images/games/cd11.png';
import cd12 from '../../assets/images/games/cd12.png';
import GameButton from './GameButton';

function CardContentList() {

  return (
    <div className='roll-out'>
      <Row gutter={[13, 8]} style={{ marginLeft: '5px', marginTop: '10px' }}>
        <GameButton icon={cd1} />
        <GameButton icon={cd2} />
        <GameButton icon={cd3} />
        <GameButton icon={cd4} />
        <GameButton icon={cd5} />
        <GameButton icon={cd6} />
        <GameButton icon={cd7} />
        <GameButton icon={cd8} />
        <GameButton icon={cd9} />
        <GameButton icon={cd10} />
        <GameButton icon={cd11} />
        <GameButton icon={cd12} />
      </Row>
    </div>
  );
}

export default CardContentList;
