import { Row } from 'antd';
import React from 'react';
import fs1 from '../../assets/images/games/fs1.png';
import fs2 from '../../assets/images/games/fs2.png';
import fs3 from '../../assets/images/games/fs3.png';
import fs4 from '../../assets/images/games/fs4.png';
import fs5 from '../../assets/images/games/fs5.png';
import fs6 from '../../assets/images/games/fs6.png';
import fs7 from '../../assets/images/games/fs7.png';
import fs8 from '../../assets/images/games/fs8.png';
import fs9 from '../../assets/images/games/fs9.png';
import fs10 from '../../assets/images/games/fs10.png';
import fs11 from '../../assets/images/games/fs11.png';
import fs12 from '../../assets/images/games/fs12.png';
import GameButton from './GameButton';

function FishContentList() {

  return (
    <div className='roll-out'>
      <Row gutter={[13, 8]} style={{ marginLeft: '5px', marginTop: '10px' }}>
        <GameButton icon={fs1} />
        <GameButton icon={fs2} />
        <GameButton icon={fs3} />
        <GameButton icon={fs4} />
        <GameButton icon={fs5} />
        <GameButton icon={fs6} />
        <GameButton icon={fs7} />
        <GameButton icon={fs8} />
        <GameButton icon={fs9} />
        <GameButton icon={fs10} />
        <GameButton icon={fs11} />
        <GameButton icon={fs12} />
      </Row>
    </div>
  );
}

export default FishContentList;
