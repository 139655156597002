import React, { useState, useEffect } from 'react';
import { Button, Carousel, Checkbox, Col, Form, Input, message, Row } from 'antd';
import { useHistory } from 'react-router';
import hot from '../../assets/images/icn-hot.png';
import hotChecked from '../../assets/images/icn-hot-checked.png';
import sportbook from '../../assets/images/icn-sportsbook.png';
import sportbookChecked from '../../assets/images/icn-sportsbook-check.png';
import lc from '../../assets/images/icn-lc.png';
import lcChecked from '../../assets/images/icn-lc-checked.png';
import fishing from '../../assets/images/icn-fishing.png';
import fishingChecked from '../../assets/images/icn-fishing-checked.png';
import itpSlotChecked from '../../assets/images/icn-itpslot-checked.png';
import itpSlot from '../../assets/images/icn-itpslot.png';
import lottoChecked from '../../assets/images/icn-lotto-checked.png';
import lotto from '../../assets/images/icn-lotto.png';
import slotChecked from '../../assets/images/icn-ufaslot-checked.png';
import slot from '../../assets/images/icn-ufaslot.png';
import esportsChecked from '../../assets/images/icn-esports-checked.png';
import esports from '../../assets/images/icn-esports.png';
import cardChecked from '../../assets/images/icn-card-checked.png';
import card from '../../assets/images/icn-card.png';
import sportBookGame from '../../assets/images/games/img-sportsbook-square-th.png';


import './index.css';
import MenuButton from './MenuButton';
import GameButton from './GameButton';
import HitContentList from './HitContentList';
import SportContentList from './SportContentList';
import CasinoContentList from './CasinoContentList';
import CardContentList from './CardContentList';
import LottoContentList from './LottoContentList';
import SlotContentList from './SlotContentList';
import ITPContentList from './ITPContentList';
import FishContentList from './FishContentList';
import EsportContentList from './EsportContentList';
function MenuContent(props) {
  const { onLogin } = props
  useEffect(() => { }, []);
  const [checkedIndex, setCheckedIndex] = useState(1);
  const [isAnimating, setIsAnimating] = useState(false);
  const [prevIndex, setPrevIndex] = useState(1);

  const handleButtonClick = (index) => {
    if (checkedIndex !== index) {
      setIsAnimating(true);
      setPrevIndex(checkedIndex);
      setCheckedIndex(index);
    }
  };

  const handleTransitionEnd = () => {
    setIsAnimating(false);
  };

  const renderContent = () => {
    switch (checkedIndex) {
      case 1:
        return <HitContentList onLogin={onLogin} />;
      case 2:
        return <SportContentList onLogin={onLogin} />;
      case 3:
        return <CasinoContentList />;
      case 4:
        return <CardContentList />;
      case 5:
        return <LottoContentList />;
      case 6:
        return <SlotContentList />;
      case 7:
        return <ITPContentList />;
      case 8:
        return <FishContentList />;
      case 9:
        return <EsportContentList />;
      default:
        return null;
    }
  };

  return (
    <div style={{ display: 'flex', paddingLeft: '7px', paddingRight: '7px', paddingTop: '5px' }}>
      <div>
        <MenuButton
          onClick={() => { setCheckedIndex(1) }}
          isChecked={checkedIndex === 1}
          icon={hot}
          checkedIcon={hotChecked}
          title='กำลังฮิต' />
        <MenuButton
          onClick={() => { setCheckedIndex(2) }}
          isChecked={checkedIndex === 2}
          icon={sportbook}
          checkedIcon={sportbookChecked}
          title='กีฬา' />
        <MenuButton
          onClick={() => { setCheckedIndex(3) }}
          isChecked={checkedIndex === 3}
          icon={lc}
          checkedIcon={lcChecked}
          title='คาสิโน' />
        <MenuButton
          onClick={() => { setCheckedIndex(4) }}
          isChecked={checkedIndex === 4}
          icon={card}
          checkedIcon={cardChecked}
          title='ไพ่' />
        <MenuButton
          onClick={() => { setCheckedIndex(5) }}
          isChecked={checkedIndex === 5}
          icon={lotto}
          checkedIcon={lottoChecked}
          title='หวย' />
        <MenuButton
          onClick={() => { setCheckedIndex(6) }}
          isChecked={checkedIndex === 6}
          icon={slot}
          checkedIcon={slotChecked}
          title='สล็อต' />
        <MenuButton
          onClick={() => { setCheckedIndex(7) }}
          isChecked={checkedIndex === 7}
          icon={itpSlot}
          checkedIcon={itpSlotChecked}
          title='ITP สล็อต' />
        <MenuButton
          onClick={() => { setCheckedIndex(8) }}
          isChecked={checkedIndex === 8}
          icon={fishing}
          checkedIcon={fishingChecked}
          title='ยิงปลา' />
        <MenuButton
          onClick={() => { setCheckedIndex(9) }}
          isChecked={checkedIndex === 9}
          icon={esports}
          checkedIcon={esportsChecked}
          title='อีสปอร์ต' />
      </div>
      <div>
        {renderContent()}
      </div>
    </div>
  );
}

export default MenuContent;
