import { Card } from "antd";
import moment from "moment";

function HandicapBillCard(props) {
    const { bill } = props
    return <Card
        style={{
            border: '1px solid #8c8c8c',
            borderRadius: '0px',
            marginTop: '10px',
            background: '#e4e4e4',
            fontSize: '10px',
            marginLeft: '5px',
            marginRight: '5px',
        }}
        className='padding'
    >
        <div>{`#${bill?.bill_number} (${moment(
            bill?.bet_at
        ).format('DD/MM HH:mm')})`}</div>
        <div>{`${bill?.selected_matches[0].match_id.team_a} -vs- ${bill?.selected_matches[0].match_id.team_b}`}</div>
        <div
            style={{
                fontWeight: 'bold',
                color:
                    bill?.selected_matches[0].handicap_team ===
                        bill?.selected_matches[0].selected_team
                        ? '#B50000'
                        : '',
            }}
        >
            {bill?.selected_matches[0].selected_team}
            <span
                style={{
                    color: 'black',
                    fontWeight: 'bold',
                    marginLeft: '15px',
                }}
            >
                {`(${bill?.selected_matches[0].handicap_team ===
                    bill?.selected_matches[0].selected_team
                    ? '-'
                    : ''
                    }${bill?.selected_matches[0].handicap_score}) 2.0`}
            </span>
        </div>
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <div style={{ background: 'green', color: 'white' }}>
                เดิมพันสำเร็จ
            </div>
            <div style={{ fontWeight: 'bold' }}>
                {bill?.bet_credits}
            </div>
        </div>
    </Card>
}

export default HandicapBillCard;