import { Col } from 'antd';
import React from 'react';

function GameButton(props) {
  const { icon, onClick, colSpan = 12 } = props

  return (
    <Col span={colSpan}>
      <img src={icon} onClick={onClick} style={{ width: '100%' }} />
    </Col>
  );
}

export default GameButton;
