import { Row } from 'antd';
import React from 'react';
import sportBookGame from '../../assets/images/games/img-sportsbook-square-th.png';
import hit2 from '../../assets/images/games/hit2.png';
import hit3 from '../../assets/images/games/hit3.png';
import hit4 from '../../assets/images/games/hit4.png';
import hit5 from '../../assets/images/games/hit5.png';
import hit6 from '../../assets/images/games/hit6.png';
import hit7 from '../../assets/images/games/hit7.png';
import hit8 from '../../assets/images/games/hit8.png';
import hit9 from '../../assets/images/games/hit9.png';
import hit10 from '../../assets/images/games/hit10.png';
import hit11 from '../../assets/images/games/hit11.png';
import hit12 from '../../assets/images/games/hit12.png';
import GameButton from './GameButton';

function HitContentList(props) {
  const { onLogin } = props
  return (
    <div className='roll-out'>
      <Row gutter={[13, 8]} style={{ marginLeft: '5px', marginTop: '10px' }}>
        <GameButton icon={sportBookGame} onClick={onLogin} />
        <GameButton icon={hit2} />
        <GameButton icon={hit3} />
        <GameButton icon={hit4} />
        <GameButton icon={hit5} />
        <GameButton icon={hit6} />
        <GameButton icon={hit7} />
        <GameButton icon={hit8} />
        <GameButton icon={hit9} />
        <GameButton icon={hit10} />
        <GameButton icon={hit11} />
        <GameButton icon={hit12} />
      </Row>
    </div>
  );
}

export default HitContentList;
