import { Row } from 'antd';
import React from 'react';
import es1 from '../../assets/images/games/es1.png';
import es2 from '../../assets/images/games/es2.png';
import es3 from '../../assets/images/games/es3.png';
import es4 from '../../assets/images/games/es4.png';
import es5 from '../../assets/images/games/es5.png';
import GameButton from './GameButton';

function EsportContentList() {

  return (
    <div className='roll-out'>
      <Row gutter={[13, 8]} style={{ marginLeft: '5px', marginTop: '10px' }}>
        <GameButton colSpan={24} icon={es1} />
        <GameButton colSpan={24} icon={es2} />
        <GameButton colSpan={24} icon={es3} />
        <GameButton colSpan={24} icon={es4} />
        <GameButton colSpan={24} icon={es5} />
      </Row>
    </div>
  );
}

export default EsportContentList;
